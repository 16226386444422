import Meta from '../components/Meta'
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useParams } from 'react-router'
import {
  Alert,
  Badge,
  Button,
  ButtonGroup,
  Form,
  ListGroup,
} from 'react-bootstrap'
import {
  BoxArrowInRight,
  CheckCircleFill,
  ChevronLeft,
  DropletFill,
  FileEarmark,
  FileEarmarkPlus,
  Person,
  PersonVideo3,
  Printer,
  SendCheck,
  Tag,
  UpcScan,
  Upload,
  X,
} from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'
import {
  Order_State_Enum_Enum,
  useDeleteOrderTestResultMutation,
  useGetOrderQuery,
  usePingMutation,
  useSendOrderTestMutation,
} from '../generated/urql.lab-user'
import { AuthContext } from '../provider/auth'
import BarCodeModal, { BarCodeModalType } from '../components/BarCodeModal'
import { OrderState2Label } from '../lib/order'
import LaboratoryCodeModal, {
  LaboratoryCodeModalType,
} from '../components/LaboratoryCodeModal'
import { guessStorageAddress } from '../lib/api'
import { FilenamePattern } from '../lib/testResult'
import TestResultConfirmationModal, {
  TestResultConfirmationModalType,
} from '../components/TestResultConfirmationModal'
import { ValidTestResult } from '../components/TestResultUploadModal'
import { OrderState2BgVariant } from '../lib/order'
import { isIdentity } from '../lib/identity'

const pageTitle = 'Szczegóły badania'

function renderIdentity(identity: unknown) {
  if (!isIdentity(identity)) {
    return 'Nieznany rodzaj tożsamości'
  }

  switch (identity.type) {
    case 'id_card':
      return (
        <span>
          {identity.value}{' '}
          <small>
            (dowód tożsamości, {identity.countryCode}, {identity.dateOfBirth})
          </small>
        </span>
      )
    case 'passport':
      return (
        <span>
          {identity.value}{' '}
          <small>
            (paszport, {identity.countryCode}, {identity.dateOfBirth})
          </small>
        </span>
      )
    case 'pesel':
      return (
        <span>
          {identity.value} <small>(PESEL)</small>
        </span>
      )
  }
}

const Order: FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const auth = useContext(AuthContext)
  const [{ data, error }, refetchGetOrder] = useGetOrderQuery({
    variables: { id: parseInt(id!) },
    requestPolicy: 'network-only',
  })
  const barCodeModalRef = useRef<BarCodeModalType>(null)
  const laboratoryCodeModalRef = useRef<LaboratoryCodeModalType>(null)
  const testResultConfirmationModalRef =
    useRef<TestResultConfirmationModalType>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [generalError, setGeneralError] = useState<string>()
  const [, deleteOrderTestResult] = useDeleteOrderTestResultMutation()
  const [, ping] = usePingMutation()
  const [checkedOrderTestIds, setCheckedOrderTestIds] = useState<number[]>([])
  const [{ fetching: sendOrderTestFetching }, sendOrderTest] =
    useSendOrderTestMutation()
  const [documents, setDocuments] = useState<string[]>([])

  const downloadFile = useCallback(
    async (id: number) => {
      const response = await fetch(`${guessStorageAddress()}/files/${id}`, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      })

      const blob = await response.blob()
      return window.URL.createObjectURL(blob)
    },
    [auth.accessToken]
  )

  const openFile = useCallback(
    async (id: number) => {
      try {
        window.location.assign(await downloadFile(id))
      } catch (error) {
        console.error(error)
      }
    },
    [downloadFile]
  )

  useEffect(() => {
    if (data?.order_by_pk) {
      Promise.all(
        data.order_by_pk.documents.map(async (document) =>
          downloadFile(document.fileId)
        )
      )
        .then(setDocuments)
        .catch(console.error)
    }
  }, [data?.order_by_pk, downloadFile])

  async function uploadOrderTestResult(
    orderTests: ValidTestResult['orderTests'],
    files: FileList
  ) {
    setGeneralError(undefined)

    await ping({}, { requestPolicy: 'network-only' })

    if (orderTests.length === 1) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const matches = file.name.match(FilenamePattern)
        if (matches) {
          const [, tubeCode, , laboratoryCode] = matches
          if (
            orderTests[0].tubeCode === tubeCode ||
            orderTests[0].laboratoryCode === laboratoryCode
          ) {
            continue
          }
        }

        setGeneralError(`Nieprawidłowa nazwa pliku ${file.name}`)
        return
      }
    }

    testResultConfirmationModalRef.current?.show(
      [...files].map((file) => ({
        file,
        orderTests,
      }))
    )
  }

  const backURL = useMemo(() => {
    if (!data?.order_by_pk) {
      return '/'
    } else if (
      data.order_by_pk.orderTests.every(
        (orderTest) =>
          orderTest.collectionPoint?.laboratoryId === auth.laboratoryId
      )
    ) {
      if (
        data.order_by_pk.orderTests.every(
          (orderTest) => orderTest.testResults.length > 0
        )
      ) {
        return '/finished'
      } else {
        return '/accepted'
      }
    } else if (
      data.order_by_pk.orderTests.find(
        (orderTest) =>
          orderTest.collectionPoint?.laboratoryId === auth.laboratoryId
      )
    ) {
      return '/'
    } else {
      return '/'
    }
  }, [data])

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-between d-print-none">
        <ButtonGroup>
          <Button
            className="d-flex align-items-center"
            onClick={() => navigate(backURL)}
          >
            <ChevronLeft size={20} />
            &nbsp;Wróć
          </Button>
        </ButtonGroup>
        <Button
          className="d-flex align-items-center"
          variant="outline-primary"
          onClick={() => window.print()}
        >
          <Printer size={20} />
        </Button>
      </div>
      {(error || generalError) && (
        <Alert variant="danger">{generalError || error?.message}</Alert>
      )}
      {data?.order_by_pk?.orderCollections[0] && (
        <>
          <div className="d-flex">
            <div className="flex-grow-1">
              <h4 className="mt-5">Informacje</h4>
              <p>
                <span className="fw-bold">Nr zlecenia: </span>
                {data.order_by_pk.id}
              </p>
              <p>
                <span className="fw-bold">Data zlecenia: </span>
                {new Date(
                  data.order_by_pk.orderCollections[0].collectionDate
                ).toLocaleString()}
              </p>
              <p>
                <span className="fw-bold">Osoba pobierająca: </span>
                {data.order_by_pk.nurse?.name}
              </p>
              <p>
                <span className="fw-bold">Status: </span>
                <Badge
                  bg={
                    OrderState2BgVariant[
                      data.order_by_pk.currentState as Order_State_Enum_Enum
                    ]
                  }
                >
                  {
                    OrderState2Label[
                      data.order_by_pk.currentState as Order_State_Enum_Enum
                    ]
                  }
                </Badge>
              </p>

              {!!data.order_by_pk.orderCollections[0]?.nurseNotes && (
                <>
                  <h4 className="mt-5">Notatki osoby pobierającej</h4>
                  <p style={{ whiteSpace: 'pre' }}>
                    {data.order_by_pk.orderCollections[0].nurseNotes}
                  </p>
                </>
              )}
            </div>
            <div className="flex-grow-1">
              <h4 className="mt-5">Miejsce pobrania</h4>
              <p>
                <span className="fw-bold">Adres: </span>
                {data.order_by_pk.orderCollections[0].address},{' '}
                {data.order_by_pk.orderCollections[0].postalCode}{' '}
                {data.order_by_pk.orderCollections[0].city}
              </p>
              <p>
                <span className="fw-bold">Numer telefonu: </span>
                {data.order_by_pk.orderCollections[0].phoneNumber}
              </p>
            </div>
          </div>
          {documents.length > 0 && (
            <>
              <h4 className="mt-5">Dokumenty pacjenta</h4>
              <ul className="d-flex list-unstyled">
                {documents.map((uri) => (
                  <li className="me-2">
                    <a href={uri} target="_blank" rel="noreferrer">
                      <img src={uri} alt="" className="img-thumbnail" />
                    </a>
                  </li>
                ))}
              </ul>
            </>
          )}
          <h4 className="mt-5">Badania</h4>
          <ListGroup className="w-75 w-print-100">
            {data.order_by_pk.orderTests.map((orderTest) => (
              <ListGroup.Item
                className="d-flex justify-content-between align-items-center"
                key={orderTest.id}
              >
                <Form.Check
                  className="me-1 d-print-none"
                  disabled={!!orderTest.finishedOn || !orderTest.retrievedOn}
                  checked={checkedOrderTestIds.includes(orderTest.id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setCheckedOrderTestIds([
                        ...checkedOrderTestIds,
                        orderTest.id,
                      ])
                    } else {
                      setCheckedOrderTestIds(
                        checkedOrderTestIds.filter(
                          (orderTestId) => orderTestId !== orderTest.id
                        )
                      )
                    }
                  }}
                />
                <div className="ms-2 me-auto w-100">
                  <div className="fw-bold mb-2">{orderTest.test.name}</div>
                  {orderTest.test.labInstructions && (
                    <pre className="text-muted font-sans">
                      {orderTest.test.labInstructions}
                    </pre>
                  )}
                  <div className="d-flex align-items-center">
                    <span className="text-muted w-25">Klient:</span>
                    <Person size={20} className="me-1" />
                    <span>
                      {orderTest.firstName} {orderTest.lastName}
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="text-muted w-25">
                      Dokument tożsamości:
                    </span>
                    <PersonVideo3 size={20} className="me-1" />
                    {renderIdentity(orderTest.identity)}
                  </div>
                  {orderTest.tubeCode && (
                    <div className="d-flex align-items-center">
                      <span className="text-muted w-25">Kod probówki:</span>
                      <UpcScan size={20} className="me-1" />
                      <span>{orderTest.tubeCode}</span>
                    </div>
                  )}
                  {orderTest.laboratoryCode && (
                    <div className="d-flex align-items-center">
                      <span className="text-muted w-25">Kod laboratorium:</span>
                      <Tag size={20} className="me-1" />
                      <span>{orderTest.laboratoryCode}</span>
                    </div>
                  )}
                  {orderTest.collectionPoint &&
                    !!orderTest.testResults.length &&
                    orderTest.testResults.map((testResult) => (
                      <div
                        className="d-flex align-items-center"
                        key={testResult.file.id}
                      >
                        <span className="text-muted w-25">Wynik:</span>
                        <FileEarmark size={20} className="me-1" />
                        <Button
                          onClick={() => openFile(testResult.file.id)}
                          className="p-0"
                          variant="link"
                        >
                          {testResult.originalFilename}
                        </Button>
                        {!orderTest.finishedOn && (
                          <Button
                            variant="link"
                            className="p-0 d-print-none"
                            onClick={async () => {
                              if (
                                window.confirm(
                                  'Czy napewno chcesz usunąć wynik badania?'
                                )
                              ) {
                                const { error } = await deleteOrderTestResult({
                                  fileId: testResult.file.id,
                                  orderTestId: orderTest.id,
                                })
                                if (error) {
                                  setGeneralError(error.message)
                                  return
                                }

                                refetchGetOrder({
                                  requestPolicy: 'network-only',
                                })
                              }
                            }}
                          >
                            <X size={22} color="#f56565" />
                          </Button>
                        )}
                      </div>
                    ))}
                  {orderTest.collectedOn && (
                    <div
                      className="d-flex align-items-center"
                      title="Data pobrania"
                    >
                      <span className="text-muted w-25">Pobrano:</span>
                      <DropletFill size={20} className="me-1" />
                      <span>
                        {new Date(orderTest.collectedOn).toLocaleString()}
                      </span>
                    </div>
                  )}
                  {orderTest.retrievedOn && (
                    <div
                      className="d-flex align-items-center"
                      title="Data pobrania"
                    >
                      <span className="text-muted w-25">Odebrano:</span>
                      <BoxArrowInRight size={20} className="me-1" />
                      <span>
                        {new Date(orderTest.retrievedOn).toLocaleString()}
                      </span>
                    </div>
                  )}
                  {orderTest.finishedOn && (
                    <div
                      className="d-flex align-items-center"
                      title="Data wysłania wyniku do klienta"
                    >
                      <span className="text-muted w-25">Wysłano:</span>
                      <CheckCircleFill size={20} className="me-1" />
                      <span>
                        {new Date(orderTest.finishedOn).toLocaleString()}
                      </span>
                    </div>
                  )}
                </div>
                {!orderTest.collectionPoint && (
                  <Button
                    className="ms-2 d-flex align-items-center d-print-none"
                    onClick={() => {
                      if (barCodeModalRef?.current) {
                        barCodeModalRef.current.show({
                          expectedBarcode: orderTest.tubeCode || undefined,
                        })
                      }
                    }}
                    variant="warning"
                  >
                    <UpcScan size={20} />
                  </Button>
                )}
                {orderTest.retrievedOn &&
                  orderTest.collectionPoint &&
                  !orderTest.finishedOn && (
                    <div className="d-flex align-items-center d-print-none">
                      <Button
                        title="Dodaj wynik"
                        className="ms-2 d-flex align-items-center"
                        onClick={(event) => {
                          event.currentTarget
                            .querySelectorAll('input[type=file]')
                            .forEach((input: any) => input.click())
                        }}
                      >
                        <FileEarmarkPlus size={20} />
                        <input
                          ref={fileInputRef}
                          type="file"
                          className="visually-hidden"
                          multiple
                          accept="application/pdf"
                          onChange={(event) => {
                            if (event.target.files?.length) {
                              event.target.files?.length &&
                                uploadOrderTestResult(
                                  [orderTest],
                                  event.target.files
                                )
                            }
                          }}
                        />
                      </Button>
                    </div>
                  )}
                {orderTest.collectionPoint && (
                  <Button
                    title="Kod wewnętrzny"
                    className="ms-2 d-flex align-items-center d-print-none"
                    onClick={() => {
                      if (laboratoryCodeModalRef?.current) {
                        laboratoryCodeModalRef.current.show(
                          orderTest.id,
                          orderTest.laboratoryCode || undefined
                        )
                      }
                    }}
                  >
                    <Tag size={20} />
                  </Button>
                )}
                {!orderTest.finishedOn && orderTest.testResults.length > 0 && (
                  <Button
                    title="Wyślij wyniki do pacjenta"
                    variant="success"
                    disabled={sendOrderTestFetching}
                    className="ms-2 d-flex align-items-center d-print-none"
                    onClick={async () => {
                      if (
                        window.confirm(
                          'Czy napewno chcesz wysłać wyniki badania do pacjenta?'
                        )
                      ) {
                        const { error } = await sendOrderTest({
                          id: orderTest.id,
                          now: new Date(),
                        })
                        if (error) {
                          setGeneralError(error.message)
                          return
                        }

                        refetchGetOrder({
                          requestPolicy: 'network-only',
                        })
                      }
                    }}
                  >
                    <SendCheck size={20} />
                    &nbsp;Wyślij
                  </Button>
                )}
              </ListGroup.Item>
            ))}
          </ListGroup>
          {checkedOrderTestIds.length > 0 && (
            <Button
              title="Prześlij wyniki dla wybranych badań"
              className="mt-2 d-flex align-items-center d-print-none"
              onClick={(event) => {
                event.currentTarget
                  .querySelectorAll('input[type=file]')
                  .forEach((input: any) => input.click())
              }}
            >
              <Upload size={20} className="me-2" />
              Prześlij wyniki dla wybranych badań
              <input
                ref={fileInputRef}
                type="file"
                className="visually-hidden"
                multiple
                accept="application/pdf"
                onChange={async (event) => {
                  if (event.target.files?.length && data.order_by_pk) {
                    await uploadOrderTestResult(
                      data.order_by_pk.orderTests.filter((orderTest) =>
                        checkedOrderTestIds.includes(orderTest.id)
                      ),
                      event.target.files
                    )

                    setCheckedOrderTestIds([])
                  }
                }}
              />
            </Button>
          )}
          <BarCodeModal
            ref={barCodeModalRef}
            onSuccess={() => {
              refetchGetOrder({ requestPolicy: 'network-only' })
            }}
          />
          <LaboratoryCodeModal
            ref={laboratoryCodeModalRef}
            onChange={() => {
              refetchGetOrder({ requestPolicy: 'network-only' })
            }}
          />
          <TestResultConfirmationModal
            ref={testResultConfirmationModalRef}
            onFinished={() => {
              refetchGetOrder({ requestPolicy: 'network-only' })
              if (fileInputRef.current) {
                fileInputRef.current.value = ''
              }
            }}
          />
        </>
      )}
    </div>
  )
}

export default Order
