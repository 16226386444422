import { Alert, Button, FloatingLabel, Form, Modal } from 'react-bootstrap'
import BarcodeScanner, { BarcodeScannerType } from './BarCodeScanner'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import {
  GetOrderTestByCodeDocument,
  GetOrderTestByCodeQuery,
  GetOrderTestByCodeQueryVariables,
  Order_State_Enum_Enum,
  useAcceptOrderTestMutation,
  useUpdateOrderStateMutation,
} from '../generated/urql.lab-user'
import { useClient } from 'urql'

export type BarCodeModalType = {
  show: (args?: { expectedBarcode?: string }) => void
}

const BarCodeModal = forwardRef<
  BarCodeModalType,
  {
    onSuccess: ({
      order,
    }: {
      order: GetOrderTestByCodeQuery['order_test'][number]['order']
    }) => void
  }
>(({ onSuccess }, ref) => {
  const [show, setShow] = useState(false)
  const [expectedBarcode, setExpectedBarcode] = useState<string>()
  const [barcode, setBarcode] = useState<string>()
  const scannerRef = useRef<BarcodeScannerType>(null)
  const [, acceptOrderTest] = useAcceptOrderTestMutation()
  const [, updateOrderState] = useUpdateOrderStateMutation()
  const [generalError, setGeneralError] = useState<string>()
  const [manualCodeVisible, setManualCodeVisible] = useState(true)
  const client = useClient()

  useImperativeHandle(ref, () => ({
    show: ({ expectedBarcode } = {}) => {
      setExpectedBarcode(expectedBarcode)
      setShow(true)
    },
  }))

  useEffect(() => {
    if (expectedBarcode && barcode && barcode !== expectedBarcode) {
      setGeneralError('Nieoczekiwany kod badania')
    }
  }, [barcode, expectedBarcode])

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false)
        setExpectedBarcode(undefined)
        setBarcode(undefined)
      }}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Skanuj próbkę</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {generalError && <Alert variant="danger">{generalError}</Alert>}
        {!manualCodeVisible && (
          <BarcodeScanner
            ref={scannerRef}
            width={766}
            height={574}
            onBarcode={setBarcode}
          />
        )}
        {manualCodeVisible && (
          <div>
            <FloatingLabel
              controlId="floatingInput"
              label="Kod próbki"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Kod próbki"
                value={barcode}
                onChange={(event) => setBarcode(event.target.value)}
              />
            </FloatingLabel>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {/* manualCodeVisible && (
          <Button
            style={{ marginRight: 'auto' }}
            variant="secondary"
            onClick={() => setManualCodeVisible(false)}
          >
            Skaner kodu
          </Button>
        ) */}
        {!manualCodeVisible && (
          <Button
            style={{ marginRight: 'auto' }}
            variant="secondary"
            onClick={() => setManualCodeVisible(true)}
          >
            Wpisz kod ręcznie
          </Button>
        )}
        <Button
          variant="secondary"
          disabled={!barcode}
          onClick={() => {
            scannerRef?.current && scannerRef.current.reset()
            setBarcode(undefined)
            setGeneralError(undefined)
            setManualCodeVisible(false)
          }}
        >
          Ponów
        </Button>
        <Button
          variant="success"
          disabled={
            !barcode || (!!expectedBarcode && barcode !== expectedBarcode)
          }
          onClick={async () => {
            if (!barcode) {
              setGeneralError('Barcode not defined')
              return
            }

            const { data: getOrderTestData, error: getOrderTestError } =
              await client
                .query<
                  GetOrderTestByCodeQuery,
                  GetOrderTestByCodeQueryVariables
                >(
                  GetOrderTestByCodeDocument,
                  {
                    code: barcode,
                  },
                  { requestPolicy: 'network-only' }
                )
                .toPromise()

            if (getOrderTestError) {
              setGeneralError(getOrderTestError.message)
              return
            }

            if (!getOrderTestData?.order_test) {
              setGeneralError('Expected order test data')
              return
            }

            if (getOrderTestData.order_test.length === 0) {
              setGeneralError('Nie znaleziono badania o podanym kodzie')
              return
            }

            for (const orderTest of getOrderTestData.order_test) {
              if (orderTest.finishedOn) {
                setGeneralError('Badanie zostało już zakończone')
                return
              }

              const { error: acceptOrderTestError } = await acceptOrderTest({
                id: orderTest.id,
                retrievedOn: new Date(),
              })

              if (acceptOrderTestError) {
                setGeneralError(acceptOrderTestError.message)
                return
              }
            }

            if (
              getOrderTestData.order_test[0].order.currentState !==
              Order_State_Enum_Enum.ExaminingSamples
            ) {
              const { error: updateOrderStateError } = await updateOrderState({
                orderId: getOrderTestData.order_test[0].order.id,
                state: Order_State_Enum_Enum.ExaminingSamples,
              })

              if (updateOrderStateError) {
                setGeneralError(updateOrderStateError.message)
                return
              }
            }

            setShow(false)

            onSuccess({ order: getOrderTestData.order_test[0].order })
          }}
        >
          Zapisz
        </Button>
      </Modal.Footer>
    </Modal>
  )
})

export default BarCodeModal
