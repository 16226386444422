import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  geography: { type: 'Point', coordinates: [number, number] };
  geometry: any;
  jsonb: any;
  numeric: any;
  timestamptz: any;
};

export type AdministratorLoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type AdministratorLoginOutput = {
  __typename?: 'AdministratorLoginOutput';
  accessToken: Scalars['String'];
};

export type AdministratorPasswordResetFinishInput = {
  code: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type AdministratorPasswordResetFinishOutput = {
  __typename?: 'AdministratorPasswordResetFinishOutput';
  accessToken: Scalars['String'];
};

export type AdministratorPasswordResetInitInput = {
  email: Scalars['String'];
};

export type AdministratorPasswordResetInitOutput = {
  __typename?: 'AdministratorPasswordResetInitOutput';
  result: Scalars['String'];
};

export type AdministratorRefreshTokenOutput = {
  __typename?: 'AdministratorRefreshTokenOutput';
  accessToken: Scalars['String'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type ClientComplaintSendAttachment = {
  dataUrl: Scalars['String'];
  filename: Scalars['String'];
};

export type ClientComplaintSendInput = {
  attachments: Array<ClientComplaintSendAttachment>;
  email?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  name: Scalars['String'];
  subject: Scalars['String'];
};

export type ClientComplaintSendOutput = {
  __typename?: 'ClientComplaintSendOutput';
  result: Scalars['Boolean'];
};

export type ClientContactSendInput = {
  email?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
};

export type ClientContactSendOutput = {
  __typename?: 'ClientContactSendOutput';
  result: Scalars['Boolean'];
};

export type ClientLoginInput = {
  email: Scalars['String'];
  emailCode?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  smsCode?: InputMaybe<Scalars['String']>;
};

export type ClientLoginOutput = {
  __typename?: 'ClientLoginOutput';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type ClientOrderPaymentStatusOutput = {
  __typename?: 'ClientOrderPaymentStatusOutput';
  status: Scalars['String'];
};

export type ClientPasswordResetFinishInput = {
  code: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type ClientPasswordResetFinishOutput = {
  __typename?: 'ClientPasswordResetFinishOutput';
  result: Scalars['String'];
};

export type ClientPasswordResetInitInput = {
  email: Scalars['String'];
};

export type ClientPasswordResetInitOutput = {
  __typename?: 'ClientPasswordResetInitOutput';
  result: Scalars['String'];
};

export type ClientRefreshTokenInput = {
  refreshToken?: InputMaybe<Scalars['String']>;
};

export type ClientRefreshTokenOutput = {
  __typename?: 'ClientRefreshTokenOutput';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type ClientRegisterFinishInput = {
  code: Scalars['String'];
  email: Scalars['String'];
};

export type ClientRegisterFinishOutput = {
  __typename?: 'ClientRegisterFinishOutput';
  result: Scalars['String'];
};

export type ClientRegisterInput = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  newsletterConsent: Scalars['Boolean'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  pushConsent: Scalars['Boolean'];
};

export type ClientRegisterOutput = {
  __typename?: 'ClientRegisterOutput';
  result: Scalars['String'];
};

export type ClientVerifyKdrOutput = {
  __typename?: 'ClientVerifyKdrOutput';
  valid: Scalars['Boolean'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type LabUserLoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LabUserLoginOutput = {
  __typename?: 'LabUserLoginOutput';
  accessToken: Scalars['String'];
};

export type LabUserPasswordResetFinishInput = {
  code: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LabUserPasswordResetFinishOutput = {
  __typename?: 'LabUserPasswordResetFinishOutput';
  accessToken: Scalars['String'];
};

export type LabUserPasswordResetInitInput = {
  email: Scalars['String'];
};

export type LabUserPasswordResetInitOutput = {
  __typename?: 'LabUserPasswordResetInitOutput';
  result: Scalars['String'];
};

export type LabUserRefreshTokenOutput = {
  __typename?: 'LabUserRefreshTokenOutput';
  accessToken: Scalars['String'];
};

export type NurseLoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  smsCode?: InputMaybe<Scalars['String']>;
};

export type NurseLoginOutput = {
  __typename?: 'NurseLoginOutput';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type NursePasswordResetFinishInput = {
  code: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type NursePasswordResetFinishOutput = {
  __typename?: 'NursePasswordResetFinishOutput';
  result: Scalars['String'];
};

export type NursePasswordResetInitInput = {
  email: Scalars['String'];
};

export type NursePasswordResetInitOutput = {
  __typename?: 'NursePasswordResetInitOutput';
  result: Scalars['String'];
};

export type NurseRefreshTokenInput = {
  refreshToken: Scalars['String'];
};

export type NurseRefreshTokenOutput = {
  __typename?: 'NurseRefreshTokenOutput';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type OrderAddProfileInput = {
  firstName: Scalars['String'];
  gender: Scalars['String'];
  identity: Scalars['jsonb'];
  lastName: Scalars['String'];
};

export type OrderAddTestInput = {
  profile: OrderAddProfileInput;
  testId: Scalars['Int'];
};

export type OrderAddTrainingInput = {
  trainingId: Scalars['Int'];
};

export type OrderCostCollectionInput = {
  collectionDate?: InputMaybe<Scalars['String']>;
  location: Array<Scalars['Float']>;
  tests: Array<OrderAddTestInput>;
};

export type OrderCostCollectionOutput = {
  __typename?: 'OrderCostCollectionOutput';
  collectionCost: Scalars['Float'];
  collectionCostBeforeDiscount?: Maybe<Scalars['Float']>;
  collectionKitCost: Scalars['Float'];
  collectionKitCostBeforeDiscount?: Maybe<Scalars['Float']>;
  deliveryCost: Scalars['Float'];
  deliveryCostBeforeDiscount?: Maybe<Scalars['Float']>;
  deliveryCostPerKm: Scalars['Float'];
  deliveryDistance: Scalars['Int'];
  tests: Array<OrderCostTestOutput>;
};

export type OrderCostConsultationInput = {
  consultationId: Scalars['Int'];
  preferredDate?: InputMaybe<Scalars['String']>;
  profile: OrderAddProfileInput;
};

export type OrderCostConsultationOutput = {
  __typename?: 'OrderCostConsultationOutput';
  cost: Scalars['Float'];
  costBeforeDiscount?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
};

export type OrderCostDiscountOutput = {
  __typename?: 'OrderCostDiscountOutput';
  code: Scalars['String'];
  id: Scalars['Int'];
  percent: Scalars['Float'];
  type: Scalars['String'];
};

export type OrderCostInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  collections?: InputMaybe<Array<OrderCostCollectionInput>>;
  consultations?: InputMaybe<Array<OrderCostConsultationInput>>;
  date?: InputMaybe<Scalars['String']>;
  discountCodes: Array<Scalars['String']>;
  location?: InputMaybe<Array<Scalars['Float']>>;
  postalCode?: InputMaybe<Scalars['String']>;
  tests?: InputMaybe<Array<OrderAddTestInput>>;
  trainings?: InputMaybe<Array<OrderAddTrainingInput>>;
};

export type OrderCostOutput = {
  __typename?: 'OrderCostOutput';
  collectionCost: Scalars['Float'];
  collectionCostBeforeDiscount?: Maybe<Scalars['Float']>;
  collectionKitCost: Scalars['Float'];
  collectionKitCostBeforeDiscount?: Maybe<Scalars['Float']>;
  collections: Array<OrderCostCollectionOutput>;
  consultations: Array<OrderCostConsultationOutput>;
  cost: Scalars['Float'];
  costBeforeDiscount?: Maybe<Scalars['Float']>;
  deliveryCost: Scalars['Float'];
  deliveryCostBeforeDiscount?: Maybe<Scalars['Float']>;
  deliveryCostPerKm: Scalars['Float'];
  deliveryDistance: Scalars['Int'];
  discounts: Array<OrderCostDiscountOutput>;
  tests: Array<OrderCostTestOutput>;
  trainings: Array<OrderCostTrainingOutput>;
};

export type OrderCostProfileOutput = {
  __typename?: 'OrderCostProfileOutput';
  name: Scalars['String'];
  pesel: Scalars['String'];
};

export type OrderCostTestOutput = {
  __typename?: 'OrderCostTestOutput';
  collectionPointId: Scalars['Int'];
  cost: Scalars['Float'];
  costBeforeDiscount?: Maybe<Scalars['Float']>;
  deliveryOrder: Scalars['Int'];
  id: Scalars['Int'];
  laboratoryId: Scalars['Int'];
  profile?: Maybe<OrderCostProfileOutput>;
};

export type OrderCostTrainingOutput = {
  __typename?: 'OrderCostTrainingOutput';
  cost: Scalars['Float'];
  costBeforeDiscount?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
};

export type PingOutput = {
  __typename?: 'PingOutput';
  pong: Scalars['Boolean'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type AvailableLaboratoryTests_Test_Args = {
  point?: InputMaybe<Scalars['geography']>;
};

export type Available_Laboratory_Args = {
  _limit?: InputMaybe<Scalars['Int']>;
  _offset?: InputMaybe<Scalars['Int']>;
  point?: InputMaybe<Scalars['geography']>;
};

export type Available_Test_Tag_Args = {
  _limit?: InputMaybe<Scalars['Int']>;
  _offset?: InputMaybe<Scalars['Int']>;
  point?: InputMaybe<Scalars['geography']>;
};

/** columns and relationships of "consultation" */
export type Consultation = {
  __typename?: 'consultation';
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "consultation_description_i18n" */
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** A computed field, executes function "consultation_name_i18n" */
  name?: Maybe<Scalars['String']>;
  price: Scalars['numeric'];
  svgIcon?: Maybe<Scalars['String']>;
};

/** aggregated selection of "consultation" */
export type Consultation_Aggregate = {
  __typename?: 'consultation_aggregate';
  aggregate?: Maybe<Consultation_Aggregate_Fields>;
  nodes: Array<Consultation>;
};

/** aggregate fields of "consultation" */
export type Consultation_Aggregate_Fields = {
  __typename?: 'consultation_aggregate_fields';
  avg?: Maybe<Consultation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Consultation_Max_Fields>;
  min?: Maybe<Consultation_Min_Fields>;
  stddev?: Maybe<Consultation_Stddev_Fields>;
  stddev_pop?: Maybe<Consultation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Consultation_Stddev_Samp_Fields>;
  sum?: Maybe<Consultation_Sum_Fields>;
  var_pop?: Maybe<Consultation_Var_Pop_Fields>;
  var_samp?: Maybe<Consultation_Var_Samp_Fields>;
  variance?: Maybe<Consultation_Variance_Fields>;
};


/** aggregate fields of "consultation" */
export type Consultation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Consultation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Consultation_Avg_Fields = {
  __typename?: 'consultation_avg_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "consultation". All fields are combined with a logical 'AND'. */
export type Consultation_Bool_Exp = {
  _and?: InputMaybe<Array<Consultation_Bool_Exp>>;
  _not?: InputMaybe<Consultation_Bool_Exp>;
  _or?: InputMaybe<Array<Consultation_Bool_Exp>>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  svgIcon?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Consultation_Max_Fields = {
  __typename?: 'consultation_max_fields';
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "consultation_description_i18n" */
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "consultation_name_i18n" */
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  svgIcon?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Consultation_Min_Fields = {
  __typename?: 'consultation_min_fields';
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "consultation_description_i18n" */
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "consultation_name_i18n" */
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  svgIcon?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "consultation". */
export type Consultation_Order_By = {
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  svgIcon?: InputMaybe<Order_By>;
};

/** select columns of table "consultation" */
export enum Consultation_Select_Column {
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  SvgIcon = 'svgIcon'
}

/** aggregate stddev on columns */
export type Consultation_Stddev_Fields = {
  __typename?: 'consultation_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Consultation_Stddev_Pop_Fields = {
  __typename?: 'consultation_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Consultation_Stddev_Samp_Fields = {
  __typename?: 'consultation_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "consultation" */
export type Consultation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Consultation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Consultation_Stream_Cursor_Value_Input = {
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['numeric']>;
  svgIcon?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Consultation_Sum_Fields = {
  __typename?: 'consultation_sum_fields';
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Consultation_Var_Pop_Fields = {
  __typename?: 'consultation_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Consultation_Var_Samp_Fields = {
  __typename?: 'consultation_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Consultation_Variance_Fields = {
  __typename?: 'consultation_variance_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "file" */
export type File = {
  __typename?: 'file';
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  trainings: Array<Training>;
  /** An aggregate relationship */
  trainings_aggregate: Training_Aggregate;
};


/** columns and relationships of "file" */
export type FileTrainingsArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};


/** columns and relationships of "file" */
export type FileTrainings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "file". All fields are combined with a logical 'AND'. */
export type File_Bool_Exp = {
  _and?: InputMaybe<Array<File_Bool_Exp>>;
  _not?: InputMaybe<File_Bool_Exp>;
  _or?: InputMaybe<Array<File_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  trainings?: InputMaybe<Training_Bool_Exp>;
  trainings_aggregate?: InputMaybe<Training_Aggregate_Bool_Exp>;
};

/** Ordering options when selecting data from "file". */
export type File_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  trainings_aggregate?: InputMaybe<Training_Aggregate_Order_By>;
};

/** select columns of table "file" */
export enum File_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** Streaming cursor of the table "file" */
export type File_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: File_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type File_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Geography_Cast_Exp = {
  geometry?: InputMaybe<Geometry_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export type Geography_Comparison_Exp = {
  _cast?: InputMaybe<Geography_Cast_Exp>;
  _eq?: InputMaybe<Scalars['geography']>;
  _gt?: InputMaybe<Scalars['geography']>;
  _gte?: InputMaybe<Scalars['geography']>;
  _in?: InputMaybe<Array<Scalars['geography']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['geography']>;
  _lte?: InputMaybe<Scalars['geography']>;
  _neq?: InputMaybe<Scalars['geography']>;
  _nin?: InputMaybe<Array<Scalars['geography']>>;
  /** is the column within a given distance from the given geography value */
  _st_d_within?: InputMaybe<St_D_Within_Geography_Input>;
  /** does the column spatially intersect the given geography value */
  _st_intersects?: InputMaybe<Scalars['geography']>;
};

export type Geometry_Cast_Exp = {
  geography?: InputMaybe<Geography_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export type Geometry_Comparison_Exp = {
  _cast?: InputMaybe<Geometry_Cast_Exp>;
  _eq?: InputMaybe<Scalars['geometry']>;
  _gt?: InputMaybe<Scalars['geometry']>;
  _gte?: InputMaybe<Scalars['geometry']>;
  _in?: InputMaybe<Array<Scalars['geometry']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['geometry']>;
  _lte?: InputMaybe<Scalars['geometry']>;
  _neq?: InputMaybe<Scalars['geometry']>;
  _nin?: InputMaybe<Array<Scalars['geometry']>>;
  /** is the column within a given 3D distance from the given geometry value */
  _st_3d_d_within?: InputMaybe<St_D_Within_Input>;
  /** does the column spatially intersect the given geometry value in 3D */
  _st_3d_intersects?: InputMaybe<Scalars['geometry']>;
  /** does the column contain the given geometry value */
  _st_contains?: InputMaybe<Scalars['geometry']>;
  /** does the column cross the given geometry value */
  _st_crosses?: InputMaybe<Scalars['geometry']>;
  /** is the column within a given distance from the given geometry value */
  _st_d_within?: InputMaybe<St_D_Within_Input>;
  /** is the column equal to given geometry value (directionality is ignored) */
  _st_equals?: InputMaybe<Scalars['geometry']>;
  /** does the column spatially intersect the given geometry value */
  _st_intersects?: InputMaybe<Scalars['geometry']>;
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps?: InputMaybe<Scalars['geometry']>;
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches?: InputMaybe<Scalars['geometry']>;
  /** is the column contained in the given geometry value */
  _st_within?: InputMaybe<Scalars['geometry']>;
};

export type IsAvailable_Test_Args = {
  point?: InputMaybe<Scalars['geography']>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "laboratory" */
export type Laboratory = {
  __typename?: 'laboratory';
  id: Scalars['Int'];
  /** An array relationship */
  laboratoryTests: Array<Laboratory_Test>;
  /** An aggregate relationship */
  laboratoryTests_aggregate: Laboratory_Test_Aggregate;
  /** An object relationship */
  logoFile?: Maybe<File>;
  logoFileId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};


/** columns and relationships of "laboratory" */
export type LaboratoryLaboratoryTestsArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Test_Order_By>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};


/** columns and relationships of "laboratory" */
export type LaboratoryLaboratoryTests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Test_Order_By>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};

export type Laboratory_Aggregate = {
  __typename?: 'laboratory_aggregate';
  aggregate?: Maybe<Laboratory_Aggregate_Fields>;
  nodes: Array<Laboratory>;
};

/** aggregate fields of "laboratory" */
export type Laboratory_Aggregate_Fields = {
  __typename?: 'laboratory_aggregate_fields';
  avg?: Maybe<Laboratory_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Laboratory_Max_Fields>;
  min?: Maybe<Laboratory_Min_Fields>;
  stddev?: Maybe<Laboratory_Stddev_Fields>;
  stddev_pop?: Maybe<Laboratory_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Laboratory_Stddev_Samp_Fields>;
  sum?: Maybe<Laboratory_Sum_Fields>;
  var_pop?: Maybe<Laboratory_Var_Pop_Fields>;
  var_samp?: Maybe<Laboratory_Var_Samp_Fields>;
  variance?: Maybe<Laboratory_Variance_Fields>;
};


/** aggregate fields of "laboratory" */
export type Laboratory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Laboratory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Laboratory_Avg_Fields = {
  __typename?: 'laboratory_avg_fields';
  id?: Maybe<Scalars['Float']>;
  logoFileId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "laboratory". All fields are combined with a logical 'AND'. */
export type Laboratory_Bool_Exp = {
  _and?: InputMaybe<Array<Laboratory_Bool_Exp>>;
  _not?: InputMaybe<Laboratory_Bool_Exp>;
  _or?: InputMaybe<Array<Laboratory_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  laboratoryTests?: InputMaybe<Laboratory_Test_Bool_Exp>;
  laboratoryTests_aggregate?: InputMaybe<Laboratory_Test_Aggregate_Bool_Exp>;
  logoFile?: InputMaybe<File_Bool_Exp>;
  logoFileId?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Laboratory_Max_Fields = {
  __typename?: 'laboratory_max_fields';
  id?: Maybe<Scalars['Int']>;
  logoFileId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Laboratory_Min_Fields = {
  __typename?: 'laboratory_min_fields';
  id?: Maybe<Scalars['Int']>;
  logoFileId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "laboratory". */
export type Laboratory_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryTests_aggregate?: InputMaybe<Laboratory_Test_Aggregate_Order_By>;
  logoFile?: InputMaybe<File_Order_By>;
  logoFileId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** select columns of table "laboratory" */
export enum Laboratory_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LogoFileId = 'logoFileId',
  /** column name */
  Name = 'name'
}

/** aggregate stddev on columns */
export type Laboratory_Stddev_Fields = {
  __typename?: 'laboratory_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  logoFileId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Laboratory_Stddev_Pop_Fields = {
  __typename?: 'laboratory_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  logoFileId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Laboratory_Stddev_Samp_Fields = {
  __typename?: 'laboratory_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  logoFileId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "laboratory" */
export type Laboratory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Laboratory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Laboratory_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  logoFileId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Laboratory_Sum_Fields = {
  __typename?: 'laboratory_sum_fields';
  id?: Maybe<Scalars['Int']>;
  logoFileId?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "laboratory_test" */
export type Laboratory_Test = {
  __typename?: 'laboratory_test';
  id: Scalars['Int'];
  /** An object relationship */
  laboratory: Laboratory;
  laboratoryId: Scalars['Int'];
  price: Scalars['numeric'];
  /** An object relationship */
  test: Test;
  testId: Scalars['Int'];
};

/** aggregated selection of "laboratory_test" */
export type Laboratory_Test_Aggregate = {
  __typename?: 'laboratory_test_aggregate';
  aggregate?: Maybe<Laboratory_Test_Aggregate_Fields>;
  nodes: Array<Laboratory_Test>;
};

export type Laboratory_Test_Aggregate_Bool_Exp = {
  count?: InputMaybe<Laboratory_Test_Aggregate_Bool_Exp_Count>;
};

export type Laboratory_Test_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Laboratory_Test_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "laboratory_test" */
export type Laboratory_Test_Aggregate_Fields = {
  __typename?: 'laboratory_test_aggregate_fields';
  avg?: Maybe<Laboratory_Test_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Laboratory_Test_Max_Fields>;
  min?: Maybe<Laboratory_Test_Min_Fields>;
  stddev?: Maybe<Laboratory_Test_Stddev_Fields>;
  stddev_pop?: Maybe<Laboratory_Test_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Laboratory_Test_Stddev_Samp_Fields>;
  sum?: Maybe<Laboratory_Test_Sum_Fields>;
  var_pop?: Maybe<Laboratory_Test_Var_Pop_Fields>;
  var_samp?: Maybe<Laboratory_Test_Var_Samp_Fields>;
  variance?: Maybe<Laboratory_Test_Variance_Fields>;
};


/** aggregate fields of "laboratory_test" */
export type Laboratory_Test_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "laboratory_test" */
export type Laboratory_Test_Aggregate_Order_By = {
  avg?: InputMaybe<Laboratory_Test_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Laboratory_Test_Max_Order_By>;
  min?: InputMaybe<Laboratory_Test_Min_Order_By>;
  stddev?: InputMaybe<Laboratory_Test_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Laboratory_Test_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Laboratory_Test_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Laboratory_Test_Sum_Order_By>;
  var_pop?: InputMaybe<Laboratory_Test_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Laboratory_Test_Var_Samp_Order_By>;
  variance?: InputMaybe<Laboratory_Test_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Laboratory_Test_Avg_Fields = {
  __typename?: 'laboratory_test_avg_fields';
  id?: Maybe<Scalars['Float']>;
  laboratoryId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  testId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "laboratory_test" */
export type Laboratory_Test_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "laboratory_test". All fields are combined with a logical 'AND'. */
export type Laboratory_Test_Bool_Exp = {
  _and?: InputMaybe<Array<Laboratory_Test_Bool_Exp>>;
  _not?: InputMaybe<Laboratory_Test_Bool_Exp>;
  _or?: InputMaybe<Array<Laboratory_Test_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  laboratory?: InputMaybe<Laboratory_Bool_Exp>;
  laboratoryId?: InputMaybe<Int_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  test?: InputMaybe<Test_Bool_Exp>;
  testId?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Laboratory_Test_Max_Fields = {
  __typename?: 'laboratory_test_max_fields';
  id?: Maybe<Scalars['Int']>;
  laboratoryId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  testId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "laboratory_test" */
export type Laboratory_Test_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Laboratory_Test_Min_Fields = {
  __typename?: 'laboratory_test_min_fields';
  id?: Maybe<Scalars['Int']>;
  laboratoryId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  testId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "laboratory_test" */
export type Laboratory_Test_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "laboratory_test". */
export type Laboratory_Test_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratory?: InputMaybe<Laboratory_Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  test?: InputMaybe<Test_Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** select columns of table "laboratory_test" */
export enum Laboratory_Test_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LaboratoryId = 'laboratoryId',
  /** column name */
  Price = 'price',
  /** column name */
  TestId = 'testId'
}

/** aggregate stddev on columns */
export type Laboratory_Test_Stddev_Fields = {
  __typename?: 'laboratory_test_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  laboratoryId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  testId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "laboratory_test" */
export type Laboratory_Test_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Laboratory_Test_Stddev_Pop_Fields = {
  __typename?: 'laboratory_test_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  laboratoryId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  testId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "laboratory_test" */
export type Laboratory_Test_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Laboratory_Test_Stddev_Samp_Fields = {
  __typename?: 'laboratory_test_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  laboratoryId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  testId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "laboratory_test" */
export type Laboratory_Test_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "laboratory_test" */
export type Laboratory_Test_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Laboratory_Test_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Laboratory_Test_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  laboratoryId?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['numeric']>;
  testId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Laboratory_Test_Sum_Fields = {
  __typename?: 'laboratory_test_sum_fields';
  id?: Maybe<Scalars['Int']>;
  laboratoryId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  testId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "laboratory_test" */
export type Laboratory_Test_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Laboratory_Test_Var_Pop_Fields = {
  __typename?: 'laboratory_test_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  laboratoryId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  testId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "laboratory_test" */
export type Laboratory_Test_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Laboratory_Test_Var_Samp_Fields = {
  __typename?: 'laboratory_test_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  laboratoryId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  testId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "laboratory_test" */
export type Laboratory_Test_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Laboratory_Test_Variance_Fields = {
  __typename?: 'laboratory_test_variance_fields';
  id?: Maybe<Scalars['Float']>;
  laboratoryId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  testId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "laboratory_test" */
export type Laboratory_Test_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Laboratory_Var_Pop_Fields = {
  __typename?: 'laboratory_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  logoFileId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Laboratory_Var_Samp_Fields = {
  __typename?: 'laboratory_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  logoFileId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Laboratory_Variance_Fields = {
  __typename?: 'laboratory_variance_fields';
  id?: Maybe<Scalars['Float']>;
  logoFileId?: Maybe<Scalars['Float']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  administrator_login?: Maybe<AdministratorLoginOutput>;
  administrator_password_reset_finish?: Maybe<AdministratorPasswordResetFinishOutput>;
  administrator_password_reset_init?: Maybe<AdministratorPasswordResetInitOutput>;
  administrator_refresh_token?: Maybe<AdministratorRefreshTokenOutput>;
  client_complaint_send?: Maybe<ClientComplaintSendOutput>;
  client_contact_send?: Maybe<ClientContactSendOutput>;
  client_login?: Maybe<ClientLoginOutput>;
  client_order_payment_status?: Maybe<ClientOrderPaymentStatusOutput>;
  client_password_reset_finish?: Maybe<ClientPasswordResetFinishOutput>;
  client_password_reset_init?: Maybe<ClientPasswordResetInitOutput>;
  client_refresh_token?: Maybe<ClientRefreshTokenOutput>;
  client_register?: Maybe<ClientRegisterOutput>;
  client_register_finish?: Maybe<ClientRegisterFinishOutput>;
  client_verify_kdr?: Maybe<ClientVerifyKdrOutput>;
  lab_user_login?: Maybe<LabUserLoginOutput>;
  lab_user_password_reset_finish?: Maybe<LabUserPasswordResetFinishOutput>;
  lab_user_password_reset_init?: Maybe<LabUserPasswordResetInitOutput>;
  lab_user_refresh_token?: Maybe<LabUserRefreshTokenOutput>;
  nurse_login?: Maybe<NurseLoginOutput>;
  nurse_password_reset_finish?: Maybe<NursePasswordResetFinishOutput>;
  nurse_password_reset_init?: Maybe<NursePasswordResetInitOutput>;
  nurse_refresh_token?: Maybe<NurseRefreshTokenOutput>;
  ping?: Maybe<PingOutput>;
};


/** mutation root */
export type Mutation_RootAdministrator_LoginArgs = {
  credentials: AdministratorLoginInput;
};


/** mutation root */
export type Mutation_RootAdministrator_Password_Reset_FinishArgs = {
  payload: AdministratorPasswordResetFinishInput;
};


/** mutation root */
export type Mutation_RootAdministrator_Password_Reset_InitArgs = {
  payload: AdministratorPasswordResetInitInput;
};


/** mutation root */
export type Mutation_RootClient_Complaint_SendArgs = {
  input: ClientComplaintSendInput;
};


/** mutation root */
export type Mutation_RootClient_Contact_SendArgs = {
  input: ClientContactSendInput;
};


/** mutation root */
export type Mutation_RootClient_LoginArgs = {
  credentials: ClientLoginInput;
  recaptchaToken?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootClient_Order_Payment_StatusArgs = {
  amount: Scalars['Int'];
  currency: Scalars['String'];
  merchantId: Scalars['Int'];
  methodId: Scalars['Int'];
  orderId: Scalars['Float'];
  originAmount: Scalars['Int'];
  posId: Scalars['Int'];
  sessionId: Scalars['String'];
  sign: Scalars['String'];
  statement: Scalars['String'];
};


/** mutation root */
export type Mutation_RootClient_Password_Reset_FinishArgs = {
  payload: ClientPasswordResetFinishInput;
};


/** mutation root */
export type Mutation_RootClient_Password_Reset_InitArgs = {
  payload: ClientPasswordResetInitInput;
  recaptchaToken?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootClient_Refresh_TokenArgs = {
  payload: ClientRefreshTokenInput;
};


/** mutation root */
export type Mutation_RootClient_RegisterArgs = {
  client: ClientRegisterInput;
  recaptchaToken?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootClient_Register_FinishArgs = {
  payload: ClientRegisterFinishInput;
};


/** mutation root */
export type Mutation_RootClient_Verify_KdrArgs = {
  cardNumber: Scalars['String'];
};


/** mutation root */
export type Mutation_RootLab_User_LoginArgs = {
  credentials: LabUserLoginInput;
};


/** mutation root */
export type Mutation_RootLab_User_Password_Reset_FinishArgs = {
  payload: LabUserPasswordResetFinishInput;
};


/** mutation root */
export type Mutation_RootLab_User_Password_Reset_InitArgs = {
  payload: LabUserPasswordResetInitInput;
};


/** mutation root */
export type Mutation_RootNurse_LoginArgs = {
  credentials: NurseLoginInput;
};


/** mutation root */
export type Mutation_RootNurse_Password_Reset_FinishArgs = {
  payload: NursePasswordResetFinishInput;
};


/** mutation root */
export type Mutation_RootNurse_Password_Reset_InitArgs = {
  payload: NursePasswordResetInitInput;
};


/** mutation root */
export type Mutation_RootNurse_Refresh_TokenArgs = {
  payload: NurseRefreshTokenInput;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** columns and relationships of "order_area" */
export type Order_Area = {
  __typename?: 'order_area';
  area?: Maybe<Scalars['geometry']>;
  centroid?: Maybe<Scalars['geometry']>;
};

/** Boolean expression to filter rows from the table "order_area". All fields are combined with a logical 'AND'. */
export type Order_Area_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Area_Bool_Exp>>;
  _not?: InputMaybe<Order_Area_Bool_Exp>;
  _or?: InputMaybe<Array<Order_Area_Bool_Exp>>;
  area?: InputMaybe<Geometry_Comparison_Exp>;
  centroid?: InputMaybe<Geometry_Comparison_Exp>;
};

/** Ordering options when selecting data from "order_area". */
export type Order_Area_Order_By = {
  area?: InputMaybe<Order_By>;
  centroid?: InputMaybe<Order_By>;
};

/** select columns of table "order_area" */
export enum Order_Area_Select_Column {
  /** column name */
  Area = 'area',
  /** column name */
  Centroid = 'centroid'
}

/** Streaming cursor of the table "order_area" */
export type Order_Area_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Area_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Area_Stream_Cursor_Value_Input = {
  area?: InputMaybe<Scalars['geometry']>;
  centroid?: InputMaybe<Scalars['geometry']>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** execute function "available_laboratory" which returns "laboratory" */
  available_laboratory: Array<Laboratory>;
  /** execute function "available_laboratory" and query aggregates on result of table type "laboratory" */
  available_laboratory_aggregate: Laboratory_Aggregate;
  /** execute function "available_test_tag" which returns "test_tag" */
  available_test_tag: Array<Test_Tag>;
  client_order_cost: OrderCostOutput;
  /** fetch data from the table: "consultation" */
  consultation: Array<Consultation>;
  /** fetch aggregated fields from the table: "consultation" */
  consultation_aggregate: Consultation_Aggregate;
  /** fetch data from the table: "consultation" using primary key columns */
  consultation_by_pk?: Maybe<Consultation>;
  /** fetch data from the table: "file" */
  file: Array<File>;
  /** fetch data from the table: "file" using primary key columns */
  file_by_pk?: Maybe<File>;
  /** fetch data from the table: "laboratory" */
  laboratory: Array<Laboratory>;
  /** fetch aggregated fields from the table: "laboratory" */
  laboratory_aggregate: Laboratory_Aggregate;
  /** fetch data from the table: "laboratory" using primary key columns */
  laboratory_by_pk?: Maybe<Laboratory>;
  /** fetch data from the table: "laboratory_test" */
  laboratory_test: Array<Laboratory_Test>;
  /** fetch aggregated fields from the table: "laboratory_test" */
  laboratory_test_aggregate: Laboratory_Test_Aggregate;
  /** fetch data from the table: "laboratory_test" using primary key columns */
  laboratory_test_by_pk?: Maybe<Laboratory_Test>;
  /** fetch data from the table: "order_area" */
  order_area: Array<Order_Area>;
  /** execute function "search_test" which returns "test" */
  search_test: Array<Test>;
  /** execute function "search_test_most_popular" which returns "test" */
  search_test_most_popular: Array<Test>;
  /** execute function "search_test_tag" which returns "test" */
  search_test_tag: Array<Test>;
  /** fetch data from the table: "tag" */
  tag: Array<Tag>;
  /** fetch data from the table: "tag" using primary key columns */
  tag_by_pk?: Maybe<Tag>;
  /** fetch data from the table: "test" */
  test: Array<Test>;
  /** fetch data from the table: "test" using primary key columns */
  test_by_pk?: Maybe<Test>;
  /** execute function "test_laboratories" which returns "laboratory" */
  test_laboratories: Array<Laboratory>;
  /** execute function "test_laboratories" and query aggregates on result of table type "laboratory" */
  test_laboratories_aggregate: Laboratory_Aggregate;
  /** fetch data from the table: "test_tag" */
  test_tag: Array<Test_Tag>;
  /** fetch data from the table: "test_tag" using primary key columns */
  test_tag_by_pk?: Maybe<Test_Tag>;
  /** execute function "tests_by_collection_point_and_nurse" which returns "test" */
  tests_by_collection_point_and_nurse: Array<Test>;
  /** fetch data from the table: "training" */
  training: Array<Training>;
  /** fetch aggregated fields from the table: "training" */
  training_aggregate: Training_Aggregate;
  /** fetch data from the table: "training" using primary key columns */
  training_by_pk?: Maybe<Training>;
};


export type Query_RootAvailable_LaboratoryArgs = {
  args: Available_Laboratory_Args;
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Query_RootAvailable_Laboratory_AggregateArgs = {
  args: Available_Laboratory_Args;
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Query_RootAvailable_Test_TagArgs = {
  args: Available_Test_Tag_Args;
  distinct_on?: InputMaybe<Array<Test_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Tag_Order_By>>;
  where?: InputMaybe<Test_Tag_Bool_Exp>;
};


export type Query_RootClient_Order_CostArgs = {
  order: OrderCostInput;
};


export type Query_RootConsultationArgs = {
  distinct_on?: InputMaybe<Array<Consultation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Consultation_Order_By>>;
  where?: InputMaybe<Consultation_Bool_Exp>;
};


export type Query_RootConsultation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Consultation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Consultation_Order_By>>;
  where?: InputMaybe<Consultation_Bool_Exp>;
};


export type Query_RootConsultation_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFileArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Query_RootFile_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootLaboratoryArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Query_RootLaboratory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Query_RootLaboratory_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootLaboratory_TestArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Test_Order_By>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};


export type Query_RootLaboratory_Test_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Test_Order_By>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};


export type Query_RootLaboratory_Test_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrder_AreaArgs = {
  distinct_on?: InputMaybe<Array<Order_Area_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Area_Order_By>>;
  where?: InputMaybe<Order_Area_Bool_Exp>;
};


export type Query_RootSearch_TestArgs = {
  args: Search_Test_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Query_RootSearch_Test_Most_PopularArgs = {
  args: Search_Test_Most_Popular_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Query_RootSearch_Test_TagArgs = {
  args: Search_Test_Tag_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Query_RootTagArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


export type Query_RootTag_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootTestArgs = {
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Query_RootTest_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTest_LaboratoriesArgs = {
  args: Test_Laboratories_Args;
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Query_RootTest_Laboratories_AggregateArgs = {
  args: Test_Laboratories_Args;
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Query_RootTest_TagArgs = {
  distinct_on?: InputMaybe<Array<Test_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Tag_Order_By>>;
  where?: InputMaybe<Test_Tag_Bool_Exp>;
};


export type Query_RootTest_Tag_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTests_By_Collection_Point_And_NurseArgs = {
  args: Tests_By_Collection_Point_And_Nurse_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Query_RootTrainingArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};


export type Query_RootTraining_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};


export type Query_RootTraining_By_PkArgs = {
  id: Scalars['Int'];
};

export type Search_Test_Args = {
  _limit?: InputMaybe<Scalars['Int']>;
  _offset?: InputMaybe<Scalars['Int']>;
  point?: InputMaybe<Scalars['geography']>;
  query?: InputMaybe<Scalars['String']>;
};

export type Search_Test_Most_Popular_Args = {
  _limit?: InputMaybe<Scalars['Int']>;
  _offset?: InputMaybe<Scalars['Int']>;
  point?: InputMaybe<Scalars['geography']>;
};

export type Search_Test_Tag_Args = {
  _limit?: InputMaybe<Scalars['Int']>;
  _offset?: InputMaybe<Scalars['Int']>;
  _tag?: InputMaybe<Scalars['String']>;
  point?: InputMaybe<Scalars['geography']>;
};

export type St_D_Within_Geography_Input = {
  distance: Scalars['Float'];
  from: Scalars['geography'];
  use_spheroid?: InputMaybe<Scalars['Boolean']>;
};

export type St_D_Within_Input = {
  distance: Scalars['Float'];
  from: Scalars['geometry'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** execute function "available_laboratory" which returns "laboratory" */
  available_laboratory: Array<Laboratory>;
  /** execute function "available_laboratory" and query aggregates on result of table type "laboratory" */
  available_laboratory_aggregate: Laboratory_Aggregate;
  /** execute function "available_test_tag" which returns "test_tag" */
  available_test_tag: Array<Test_Tag>;
  /** fetch data from the table: "consultation" */
  consultation: Array<Consultation>;
  /** fetch aggregated fields from the table: "consultation" */
  consultation_aggregate: Consultation_Aggregate;
  /** fetch data from the table: "consultation" using primary key columns */
  consultation_by_pk?: Maybe<Consultation>;
  /** fetch data from the table in a streaming manner: "consultation" */
  consultation_stream: Array<Consultation>;
  /** fetch data from the table: "file" */
  file: Array<File>;
  /** fetch data from the table: "file" using primary key columns */
  file_by_pk?: Maybe<File>;
  /** fetch data from the table in a streaming manner: "file" */
  file_stream: Array<File>;
  /** fetch data from the table: "laboratory" */
  laboratory: Array<Laboratory>;
  /** fetch aggregated fields from the table: "laboratory" */
  laboratory_aggregate: Laboratory_Aggregate;
  /** fetch data from the table: "laboratory" using primary key columns */
  laboratory_by_pk?: Maybe<Laboratory>;
  /** fetch data from the table in a streaming manner: "laboratory" */
  laboratory_stream: Array<Laboratory>;
  /** fetch data from the table: "laboratory_test" */
  laboratory_test: Array<Laboratory_Test>;
  /** fetch aggregated fields from the table: "laboratory_test" */
  laboratory_test_aggregate: Laboratory_Test_Aggregate;
  /** fetch data from the table: "laboratory_test" using primary key columns */
  laboratory_test_by_pk?: Maybe<Laboratory_Test>;
  /** fetch data from the table in a streaming manner: "laboratory_test" */
  laboratory_test_stream: Array<Laboratory_Test>;
  /** fetch data from the table: "order_area" */
  order_area: Array<Order_Area>;
  /** fetch data from the table in a streaming manner: "order_area" */
  order_area_stream: Array<Order_Area>;
  /** execute function "search_test" which returns "test" */
  search_test: Array<Test>;
  /** execute function "search_test_most_popular" which returns "test" */
  search_test_most_popular: Array<Test>;
  /** execute function "search_test_tag" which returns "test" */
  search_test_tag: Array<Test>;
  /** fetch data from the table: "tag" */
  tag: Array<Tag>;
  /** fetch data from the table: "tag" using primary key columns */
  tag_by_pk?: Maybe<Tag>;
  /** fetch data from the table in a streaming manner: "tag" */
  tag_stream: Array<Tag>;
  /** fetch data from the table: "test" */
  test: Array<Test>;
  /** fetch data from the table: "test" using primary key columns */
  test_by_pk?: Maybe<Test>;
  /** execute function "test_laboratories" which returns "laboratory" */
  test_laboratories: Array<Laboratory>;
  /** execute function "test_laboratories" and query aggregates on result of table type "laboratory" */
  test_laboratories_aggregate: Laboratory_Aggregate;
  /** fetch data from the table in a streaming manner: "test" */
  test_stream: Array<Test>;
  /** fetch data from the table: "test_tag" */
  test_tag: Array<Test_Tag>;
  /** fetch data from the table: "test_tag" using primary key columns */
  test_tag_by_pk?: Maybe<Test_Tag>;
  /** fetch data from the table in a streaming manner: "test_tag" */
  test_tag_stream: Array<Test_Tag>;
  /** execute function "tests_by_collection_point_and_nurse" which returns "test" */
  tests_by_collection_point_and_nurse: Array<Test>;
  /** fetch data from the table: "training" */
  training: Array<Training>;
  /** fetch aggregated fields from the table: "training" */
  training_aggregate: Training_Aggregate;
  /** fetch data from the table: "training" using primary key columns */
  training_by_pk?: Maybe<Training>;
  /** fetch data from the table in a streaming manner: "training" */
  training_stream: Array<Training>;
};


export type Subscription_RootAvailable_LaboratoryArgs = {
  args: Available_Laboratory_Args;
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Subscription_RootAvailable_Laboratory_AggregateArgs = {
  args: Available_Laboratory_Args;
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Subscription_RootAvailable_Test_TagArgs = {
  args: Available_Test_Tag_Args;
  distinct_on?: InputMaybe<Array<Test_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Tag_Order_By>>;
  where?: InputMaybe<Test_Tag_Bool_Exp>;
};


export type Subscription_RootConsultationArgs = {
  distinct_on?: InputMaybe<Array<Consultation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Consultation_Order_By>>;
  where?: InputMaybe<Consultation_Bool_Exp>;
};


export type Subscription_RootConsultation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Consultation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Consultation_Order_By>>;
  where?: InputMaybe<Consultation_Bool_Exp>;
};


export type Subscription_RootConsultation_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootConsultation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Consultation_Stream_Cursor_Input>>;
  where?: InputMaybe<Consultation_Bool_Exp>;
};


export type Subscription_RootFileArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Subscription_RootFile_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFile_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<File_Stream_Cursor_Input>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Subscription_RootLaboratoryArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Subscription_RootLaboratory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Subscription_RootLaboratory_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLaboratory_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Laboratory_Stream_Cursor_Input>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Subscription_RootLaboratory_TestArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Test_Order_By>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};


export type Subscription_RootLaboratory_Test_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Test_Order_By>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};


export type Subscription_RootLaboratory_Test_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLaboratory_Test_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Laboratory_Test_Stream_Cursor_Input>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};


export type Subscription_RootOrder_AreaArgs = {
  distinct_on?: InputMaybe<Array<Order_Area_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Area_Order_By>>;
  where?: InputMaybe<Order_Area_Bool_Exp>;
};


export type Subscription_RootOrder_Area_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Order_Area_Stream_Cursor_Input>>;
  where?: InputMaybe<Order_Area_Bool_Exp>;
};


export type Subscription_RootSearch_TestArgs = {
  args: Search_Test_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Subscription_RootSearch_Test_Most_PopularArgs = {
  args: Search_Test_Most_Popular_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Subscription_RootSearch_Test_TagArgs = {
  args: Search_Test_Tag_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Subscription_RootTagArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


export type Subscription_RootTag_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootTag_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tag_Stream_Cursor_Input>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


export type Subscription_RootTestArgs = {
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Subscription_RootTest_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTest_LaboratoriesArgs = {
  args: Test_Laboratories_Args;
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Subscription_RootTest_Laboratories_AggregateArgs = {
  args: Test_Laboratories_Args;
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Subscription_RootTest_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Test_Stream_Cursor_Input>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Subscription_RootTest_TagArgs = {
  distinct_on?: InputMaybe<Array<Test_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Tag_Order_By>>;
  where?: InputMaybe<Test_Tag_Bool_Exp>;
};


export type Subscription_RootTest_Tag_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTest_Tag_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Test_Tag_Stream_Cursor_Input>>;
  where?: InputMaybe<Test_Tag_Bool_Exp>;
};


export type Subscription_RootTests_By_Collection_Point_And_NurseArgs = {
  args: Tests_By_Collection_Point_And_Nurse_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Subscription_RootTrainingArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};


export type Subscription_RootTraining_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};


export type Subscription_RootTraining_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTraining_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Training_Stream_Cursor_Input>>;
  where?: InputMaybe<Training_Bool_Exp>;
};

/** columns and relationships of "tag" */
export type Tag = {
  __typename?: 'tag';
  id: Scalars['String'];
  /** A computed field, executes function "tag_tag_i18n" */
  tag?: Maybe<Scalars['String']>;
  tag_i18n: Scalars['jsonb'];
  /** An array relationship */
  testTags: Array<Test_Tag>;
};


/** columns and relationships of "tag" */
export type TagTag_I18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "tag" */
export type TagTestTagsArgs = {
  distinct_on?: InputMaybe<Array<Test_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Tag_Order_By>>;
  where?: InputMaybe<Test_Tag_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "tag". All fields are combined with a logical 'AND'. */
export type Tag_Bool_Exp = {
  _and?: InputMaybe<Array<Tag_Bool_Exp>>;
  _not?: InputMaybe<Tag_Bool_Exp>;
  _or?: InputMaybe<Array<Tag_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  tag?: InputMaybe<String_Comparison_Exp>;
  tag_i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  testTags?: InputMaybe<Test_Tag_Bool_Exp>;
};

/** Ordering options when selecting data from "tag". */
export type Tag_Order_By = {
  id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  tag_i18n?: InputMaybe<Order_By>;
  testTags_aggregate?: InputMaybe<Test_Tag_Aggregate_Order_By>;
};

/** select columns of table "tag" */
export enum Tag_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TagI18n = 'tag_i18n'
}

/** Streaming cursor of the table "tag" */
export type Tag_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tag_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tag_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  tag_i18n?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "test" */
export type Test = {
  __typename?: 'test';
  /** A computed field, executes function "test_laboratory_tests" */
  availableLaboratoryTests?: Maybe<Array<Laboratory_Test>>;
  bloodlabEligible: Scalars['Boolean'];
  /** A computed field, executes function "test_description_i18n" */
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** A computed field, executes function "test_available" */
  isAvailable?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  laboratoryTests: Array<Laboratory_Test>;
  /** An aggregate relationship */
  laboratoryTests_aggregate: Laboratory_Test_Aggregate;
  mostPopular: Scalars['Boolean'];
  /** A computed field, executes function "test_name_i18n" */
  name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "test_preparation_i18n" */
  preparation?: Maybe<Scalars['String']>;
  /** A computed field, executes function "test_price" */
  price?: Maybe<Scalars['numeric']>;
  /** A computed field, executes function "test_short_name_i18n" */
  shortName?: Maybe<Scalars['String']>;
  svgIcon?: Maybe<Scalars['String']>;
  /** An array relationship */
  testTags: Array<Test_Tag>;
  /** A computed field, executes function "test_waiting_time_i18n" */
  waitingTime?: Maybe<Scalars['String']>;
};


/** columns and relationships of "test" */
export type TestAvailableLaboratoryTestsArgs = {
  args: AvailableLaboratoryTests_Test_Args;
  distinct_on?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Test_Order_By>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};


/** columns and relationships of "test" */
export type TestIsAvailableArgs = {
  args: IsAvailable_Test_Args;
};


/** columns and relationships of "test" */
export type TestLaboratoryTestsArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Test_Order_By>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};


/** columns and relationships of "test" */
export type TestLaboratoryTests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Test_Order_By>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};


/** columns and relationships of "test" */
export type TestTestTagsArgs = {
  distinct_on?: InputMaybe<Array<Test_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Tag_Order_By>>;
  where?: InputMaybe<Test_Tag_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "test". All fields are combined with a logical 'AND'. */
export type Test_Bool_Exp = {
  _and?: InputMaybe<Array<Test_Bool_Exp>>;
  _not?: InputMaybe<Test_Bool_Exp>;
  _or?: InputMaybe<Array<Test_Bool_Exp>>;
  bloodlabEligible?: InputMaybe<Boolean_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  laboratoryTests?: InputMaybe<Laboratory_Test_Bool_Exp>;
  laboratoryTests_aggregate?: InputMaybe<Laboratory_Test_Aggregate_Bool_Exp>;
  mostPopular?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  preparation?: InputMaybe<String_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  shortName?: InputMaybe<String_Comparison_Exp>;
  svgIcon?: InputMaybe<String_Comparison_Exp>;
  testTags?: InputMaybe<Test_Tag_Bool_Exp>;
  waitingTime?: InputMaybe<String_Comparison_Exp>;
};

export type Test_Laboratories_Args = {
  _test_id?: InputMaybe<Scalars['Int']>;
  point?: InputMaybe<Scalars['geography']>;
};

/** Ordering options when selecting data from "test". */
export type Test_Order_By = {
  bloodlabEligible?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laboratoryTests_aggregate?: InputMaybe<Laboratory_Test_Aggregate_Order_By>;
  mostPopular?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  preparation?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  shortName?: InputMaybe<Order_By>;
  svgIcon?: InputMaybe<Order_By>;
  testTags_aggregate?: InputMaybe<Test_Tag_Aggregate_Order_By>;
  waitingTime?: InputMaybe<Order_By>;
};

/** select columns of table "test" */
export enum Test_Select_Column {
  /** column name */
  BloodlabEligible = 'bloodlabEligible',
  /** column name */
  Id = 'id',
  /** column name */
  MostPopular = 'mostPopular',
  /** column name */
  SvgIcon = 'svgIcon'
}

/** Streaming cursor of the table "test" */
export type Test_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Test_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Test_Stream_Cursor_Value_Input = {
  bloodlabEligible?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  mostPopular?: InputMaybe<Scalars['Boolean']>;
  svgIcon?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "test_tag" */
export type Test_Tag = {
  __typename?: 'test_tag';
  /** An object relationship */
  Tag: Tag;
  id: Scalars['Int'];
  tag?: Maybe<Scalars['String']>;
  /** An object relationship */
  test: Test;
  testId: Scalars['Int'];
};

/** order by aggregate values of table "test_tag" */
export type Test_Tag_Aggregate_Order_By = {
  avg?: InputMaybe<Test_Tag_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Test_Tag_Max_Order_By>;
  min?: InputMaybe<Test_Tag_Min_Order_By>;
  stddev?: InputMaybe<Test_Tag_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Test_Tag_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Test_Tag_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Test_Tag_Sum_Order_By>;
  var_pop?: InputMaybe<Test_Tag_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Test_Tag_Var_Samp_Order_By>;
  variance?: InputMaybe<Test_Tag_Variance_Order_By>;
};

/** order by avg() on columns of table "test_tag" */
export type Test_Tag_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "test_tag". All fields are combined with a logical 'AND'. */
export type Test_Tag_Bool_Exp = {
  Tag?: InputMaybe<Tag_Bool_Exp>;
  _and?: InputMaybe<Array<Test_Tag_Bool_Exp>>;
  _not?: InputMaybe<Test_Tag_Bool_Exp>;
  _or?: InputMaybe<Array<Test_Tag_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  tag?: InputMaybe<String_Comparison_Exp>;
  test?: InputMaybe<Test_Bool_Exp>;
  testId?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "test_tag" */
export type Test_Tag_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "test_tag" */
export type Test_Tag_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "test_tag". */
export type Test_Tag_Order_By = {
  Tag?: InputMaybe<Tag_Order_By>;
  id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  test?: InputMaybe<Test_Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** select columns of table "test_tag" */
export enum Test_Tag_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Tag = 'tag',
  /** column name */
  TestId = 'testId'
}

/** order by stddev() on columns of table "test_tag" */
export type Test_Tag_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "test_tag" */
export type Test_Tag_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "test_tag" */
export type Test_Tag_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "test_tag" */
export type Test_Tag_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Test_Tag_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Test_Tag_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  tag?: InputMaybe<Scalars['String']>;
  testId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "test_tag" */
export type Test_Tag_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "test_tag" */
export type Test_Tag_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "test_tag" */
export type Test_Tag_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "test_tag" */
export type Test_Tag_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

export type Tests_By_Collection_Point_And_Nurse_Args = {
  collectionpointid?: InputMaybe<Scalars['Int']>;
  nurseid?: InputMaybe<Scalars['Int']>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "training" */
export type Training = {
  __typename?: 'training';
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "training_description_i18n" */
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** A computed field, executes function "training_name_i18n" */
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  previewFile: File;
  previewFileId: Scalars['Int'];
  price: Scalars['numeric'];
  /** An object relationship */
  videoFile: File;
  videoFileId: Scalars['Int'];
};

/** aggregated selection of "training" */
export type Training_Aggregate = {
  __typename?: 'training_aggregate';
  aggregate?: Maybe<Training_Aggregate_Fields>;
  nodes: Array<Training>;
};

export type Training_Aggregate_Bool_Exp = {
  count?: InputMaybe<Training_Aggregate_Bool_Exp_Count>;
};

export type Training_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Training_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Training_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "training" */
export type Training_Aggregate_Fields = {
  __typename?: 'training_aggregate_fields';
  avg?: Maybe<Training_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Training_Max_Fields>;
  min?: Maybe<Training_Min_Fields>;
  stddev?: Maybe<Training_Stddev_Fields>;
  stddev_pop?: Maybe<Training_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Training_Stddev_Samp_Fields>;
  sum?: Maybe<Training_Sum_Fields>;
  var_pop?: Maybe<Training_Var_Pop_Fields>;
  var_samp?: Maybe<Training_Var_Samp_Fields>;
  variance?: Maybe<Training_Variance_Fields>;
};


/** aggregate fields of "training" */
export type Training_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Training_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "training" */
export type Training_Aggregate_Order_By = {
  avg?: InputMaybe<Training_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Training_Max_Order_By>;
  min?: InputMaybe<Training_Min_Order_By>;
  stddev?: InputMaybe<Training_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Training_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Training_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Training_Sum_Order_By>;
  var_pop?: InputMaybe<Training_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Training_Var_Samp_Order_By>;
  variance?: InputMaybe<Training_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Training_Avg_Fields = {
  __typename?: 'training_avg_fields';
  id?: Maybe<Scalars['Float']>;
  previewFileId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  videoFileId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "training" */
export type Training_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "training". All fields are combined with a logical 'AND'. */
export type Training_Bool_Exp = {
  _and?: InputMaybe<Array<Training_Bool_Exp>>;
  _not?: InputMaybe<Training_Bool_Exp>;
  _or?: InputMaybe<Array<Training_Bool_Exp>>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  previewFile?: InputMaybe<File_Bool_Exp>;
  previewFileId?: InputMaybe<Int_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  videoFile?: InputMaybe<File_Bool_Exp>;
  videoFileId?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Training_Max_Fields = {
  __typename?: 'training_max_fields';
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "training_description_i18n" */
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "training_name_i18n" */
  name?: Maybe<Scalars['String']>;
  previewFileId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  videoFileId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "training" */
export type Training_Max_Order_By = {
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Training_Min_Fields = {
  __typename?: 'training_min_fields';
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "training_description_i18n" */
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "training_name_i18n" */
  name?: Maybe<Scalars['String']>;
  previewFileId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  videoFileId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "training" */
export type Training_Min_Order_By = {
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "training". */
export type Training_Order_By = {
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  previewFile?: InputMaybe<File_Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFile?: InputMaybe<File_Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** select columns of table "training" */
export enum Training_Select_Column {
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  PreviewFileId = 'previewFileId',
  /** column name */
  Price = 'price',
  /** column name */
  VideoFileId = 'videoFileId'
}

/** aggregate stddev on columns */
export type Training_Stddev_Fields = {
  __typename?: 'training_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  previewFileId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  videoFileId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "training" */
export type Training_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Training_Stddev_Pop_Fields = {
  __typename?: 'training_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  previewFileId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  videoFileId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "training" */
export type Training_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Training_Stddev_Samp_Fields = {
  __typename?: 'training_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  previewFileId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  videoFileId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "training" */
export type Training_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "training" */
export type Training_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Training_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Training_Stream_Cursor_Value_Input = {
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  previewFileId?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['numeric']>;
  videoFileId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Training_Sum_Fields = {
  __typename?: 'training_sum_fields';
  id?: Maybe<Scalars['Int']>;
  previewFileId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  videoFileId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "training" */
export type Training_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Training_Var_Pop_Fields = {
  __typename?: 'training_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  previewFileId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  videoFileId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "training" */
export type Training_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Training_Var_Samp_Fields = {
  __typename?: 'training_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  previewFileId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  videoFileId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "training" */
export type Training_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Training_Variance_Fields = {
  __typename?: 'training_variance_fields';
  id?: Maybe<Scalars['Float']>;
  previewFileId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  videoFileId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "training" */
export type Training_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'mutation_root', lab_user_login?: { __typename?: 'LabUserLoginOutput', accessToken: string } | null };

export type PasswordResetFinishMutationVariables = Exact<{
  code: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type PasswordResetFinishMutation = { __typename?: 'mutation_root', lab_user_password_reset_finish?: { __typename?: 'LabUserPasswordResetFinishOutput', accessToken: string } | null };

export type PasswordResetInitMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type PasswordResetInitMutation = { __typename?: 'mutation_root', lab_user_password_reset_init?: { __typename?: 'LabUserPasswordResetInitOutput', result: string } | null };

export type RefreshTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshTokenMutation = { __typename?: 'mutation_root', lab_user_refresh_token?: { __typename?: 'LabUserRefreshTokenOutput', accessToken: string } | null };


export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  lab_user_login(credentials: {email: $email, password: $password}) {
    accessToken
  }
}
    `;

export function useLoginMutation() {
  return Urql.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument);
};
export const PasswordResetFinishDocument = gql`
    mutation PasswordResetFinish($code: String!, $email: String!, $password: String!) {
  lab_user_password_reset_finish(
    payload: {code: $code, email: $email, password: $password}
  ) {
    accessToken
  }
}
    `;

export function usePasswordResetFinishMutation() {
  return Urql.useMutation<PasswordResetFinishMutation, PasswordResetFinishMutationVariables>(PasswordResetFinishDocument);
};
export const PasswordResetInitDocument = gql`
    mutation PasswordResetInit($email: String!) {
  lab_user_password_reset_init(payload: {email: $email}) {
    result
  }
}
    `;

export function usePasswordResetInitMutation() {
  return Urql.useMutation<PasswordResetInitMutation, PasswordResetInitMutationVariables>(PasswordResetInitDocument);
};
export const RefreshTokenDocument = gql`
    mutation RefreshToken {
  lab_user_refresh_token {
    accessToken
  }
}
    `;

export function useRefreshTokenMutation() {
  return Urql.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument);
};