import { Nav, Container, Navbar, Button, NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useContext, useRef } from 'react'
import { AuthContext } from '../provider/auth'
import { BoxArrowRight, FileEarmarkPlus, UpcScan } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router'
import BarCodeModal, { BarCodeModalType } from './BarCodeModal'
import TestResultUploadModal, {
  TestResultUploadModalType,
} from './TestResultUploadModal'
import { useLocation } from 'react-router-dom'
import TestResultConfirmationModal, {
  TestResultConfirmationModalType,
} from './TestResultConfirmationModal'
import { useLoggedUser } from '../hooks/loggedUser'
import { useLogout } from '../hooks/logout'
import { useIdleTimer } from 'react-idle-timer'

const LogoutTimeout = 15 * 60 * 1000

const Menu = () => {
  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const barcodeModalRef = useRef<BarCodeModalType>(null)
  const testResultUploadModalRef = useRef<TestResultUploadModalType>(null)
  const testResultConfirmationModalRef =
    useRef<TestResultConfirmationModalType>(null)
  const location = useLocation()
  const loggedUser = useLoggedUser()
  const logout = useLogout()
  useIdleTimer({ timeout: LogoutTimeout, onIdle: logout })

  return (
    <Navbar bg="dark" expand="lg" variant="dark" className="d-print-none">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>HomeLab</Navbar.Brand>
        </LinkContainer>
        {auth.isLogged && (
          <>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <LinkContainer to="/">
                  <Nav.Link>Zgłoszone</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/accepted">
                  <Nav.Link>Przyjęte</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/finished">
                  <Nav.Link>Wykonane</Nav.Link>
                </LinkContainer>
                <Button
                  className="d-flex align-items-center ms-3"
                  onClick={() => barcodeModalRef.current?.show()}
                  variant="outline-warning"
                >
                  <UpcScan size={20} />
                  &nbsp;Skanuj próbkę
                </Button>
                <Button
                  className="d-flex align-items-center ms-3"
                  onClick={() => testResultUploadModalRef.current?.show()}
                  variant="outline-primary"
                >
                  <FileEarmarkPlus size={20} />
                  &nbsp;Dodaj wyniki
                </Button>
              </Nav>
              <Nav>
                <NavDropdown
                  align="end"
                  title={
                    <div className="avatar-circle-xs">
                      <span className="initials">{loggedUser.initials}</span>
                    </div>
                  }
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.ItemText>
                    <span className="text-nowrap">
                      Zalogowany jako: <strong>{loggedUser.name}</strong>
                    </span>
                  </NavDropdown.ItemText>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={logout}>
                    Wyloguj <BoxArrowRight size={20} />
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </Container>
      <BarCodeModal
        ref={barcodeModalRef}
        onSuccess={({ order }) => navigate(`/order/${order.id}`)}
      />
      <TestResultUploadModal
        ref={testResultUploadModalRef}
        onFinished={(testResults) => {
          testResultConfirmationModalRef.current?.show(testResults)
        }}
      />
      <TestResultConfirmationModal
        ref={testResultConfirmationModalRef}
        onFinished={(testResults) => {
          if (testResults.length > 0) {
            const target = `/order/${testResults[0].orderTests[0].order.id}`
            if (location.pathname === target) {
              window.location.reload()
            } else {
              navigate(target)
            }
          }
        }}
      />
    </Navbar>
  )
}

export default Menu
