import { Button, ListGroup, Modal } from 'react-bootstrap'
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import {
  GetOrderTestByResultFileDocument,
  GetOrderTestByResultFileQuery,
  GetOrderTestByResultFileQueryVariables,
} from '../generated/urql.lab-user'
import {
  ArrowCounterclockwise,
  Check,
  DropletHalf,
  FileEarmark,
  Tag,
  UpcScan,
  X,
} from 'react-bootstrap-icons'
import { useClient } from 'urql'
import { AuthContext } from '../provider/auth'
import { FilenamePattern } from '../lib/testResult'

export type TestResultUploadModalType = {
  show: () => void
}

export type ValidTestResult = { file: File } & {
  orderTests: Array<
    Pick<
      GetOrderTestByResultFileQuery['order_test'][number],
      | 'id'
      | 'test'
      | 'tubeCode'
      | 'laboratoryCode'
      | 'firstName'
      | 'lastName'
      | 'identity'
      | 'order'
    >
  >
}
export type InvalidTestResult = { file: File } & { error: string }
type TestResult = ValidTestResult | InvalidTestResult

const TestResultUploadModal = forwardRef<
  TestResultUploadModalType,
  {
    onFinished: (testResults: ValidTestResult[]) => void
  }
>(({ onFinished }, ref) => {
  const [show, setShow] = useState(false)
  const dropZoneRef = useRef<HTMLDivElement>(null)
  const [testResults, setTestResults] = useState<TestResult[]>()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const client = useClient()
  const auth = useContext(AuthContext)

  const handleFiles = useCallback(
    async (files: FileList) => {
      const testResults: TestResult[] = []

      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const matches = file.name.match(FilenamePattern)
        if (matches) {
          const [, tubeCode, , laboratoryCode] = matches
          if (tubeCode || laboratoryCode) {
            const { error, data } = await client
              .query<
                GetOrderTestByResultFileQuery,
                GetOrderTestByResultFileQueryVariables
              >(GetOrderTestByResultFileDocument, {
                where: {
                  ...(laboratoryCode
                    ? { laboratoryCode: { _eq: laboratoryCode } }
                    : { tubeCode: { _eq: tubeCode } }),
                  collectionPoint: { laboratoryId: { _eq: auth.laboratoryId } },
                },
              })
              .toPromise()

            if (error) {
              testResults.push({ file, error: error.message })
            } else if (data) {
              if (data.order_test.length === 0) {
                testResults.push({ file, error: 'Nie znaleziono badania' })
              } else {
                testResults.push({
                  file,
                  orderTests: data.order_test.slice(0, 1),
                })
              }
            }
          } else {
            testResults.push({ file, error: 'Nieprawidłowa nazwa pliku' })
          }
        } else {
          testResults.push({ file, error: 'Nieprawidłowa nazwa pliku' })
        }
      }

      setTestResults(testResults)
    },
    [setTestResults, client, auth.laboratoryId]
  )

  useEffect(() => {
    const dz = dropZoneRef.current
    if (dz) {
      const preventDefault = (e: Event) => {
        e.preventDefault()
        e.stopPropagation()
      }
      const drop = (event: DragEvent) => {
        const files = event.dataTransfer?.files
        if (files) {
          handleFiles(files)
        }
      }
      ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
        dz.addEventListener(eventName, preventDefault, false)
      })

      dz.addEventListener('drop', drop)

      return () => {
        dz.removeEventListener('drop', drop)
        ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
          dz.removeEventListener(eventName, preventDefault)
        })
      }
    }
  }, [dropZoneRef, handleFiles])

  useImperativeHandle(ref, () => ({
    show: () => {
      setTestResults(undefined)
      setShow(true)
    },
  }))

  const doUploadTestResults = useCallback(async () => {
    if (testResults) {
      setShow(false)
      onFinished(
        testResults.filter(
          (testResult): testResult is ValidTestResult =>
            'orderTests' in testResult
        )
      )
    }
  }, [onFinished, testResults])

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false)
      }}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Dodawanie wielu wyników</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!testResults && (
          <div
            ref={dropZoneRef}
            onClick={() => fileInputRef.current?.click()}
            className="rounded-3 m-5 d-flex align-items-center justify-content-center"
            style={{
              height: '20rem',
              border: '3px dashed #999',
              cursor: 'pointer',
            }}
          >
            <span className="fw-bold fs-2 text-secondary">
              Upuść wyniki lub kliknij
            </span>
            <input
              ref={fileInputRef}
              type="file"
              className="visually-hidden"
              multiple
              accept="application/pdf"
              onChange={(event) => {
                event.target.files && handleFiles(event.target.files)
              }}
            />
          </div>
        )}
        {!!testResults && (
          <ListGroup>
            {testResults.map((testResult) => (
              <ListGroup.Item
                variant={'error' in testResult ? 'danger' : undefined}
                key={testResult.file.name}
                className="d-flex align-items-center"
              >
                <div className="me-auto">
                  <div className="d-flex align-items-center fw-bold">
                    <FileEarmark size={20} className="me-1" />
                    {testResult.file.name}
                  </div>
                  {'orderTests' in testResult && (
                    <>
                      <div className="d-flex align-items-center">
                        <DropletHalf size={20} className="me-1" />
                        {testResult.orderTests[0].test.name}
                      </div>
                      <div className="d-flex align-items-center">
                        <UpcScan size={20} className="me-1" />
                        {testResult.orderTests[0].tubeCode}
                      </div>
                      {testResult.orderTests[0].laboratoryCode && (
                        <div className="d-flex align-items-center">
                          <Tag size={20} className="me-1" />
                          <span>{testResult.orderTests[0].laboratoryCode}</span>
                        </div>
                      )}
                    </>
                  )}
                  {'error' in testResult && (
                    <div className="d-flex align-items-center">
                      <X size={20} className="me-1" />
                      <span className="text-danger">{testResult.error}</span>
                    </div>
                  )}
                </div>
                {'orderTest' in testResult && (
                  <Check size={50} color="#19c754" />
                )}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setTestResults(undefined)
          }}
          disabled={!testResults}
          className="d-flex align-items-center"
        >
          <ArrowCounterclockwise size={20} className="me-1" />
          Reset
        </Button>
        <Button
          variant="success"
          className="d-flex align-items-center"
          disabled={
            !testResults ||
            !testResults.length ||
            !!testResults.find((tr) => 'error' in tr)
          }
          onClick={doUploadTestResults}
        >
          <Check size={20} className="me-1" />
          Dalej
        </Button>
      </Modal.Footer>
    </Modal>
  )
})

export default TestResultUploadModal
