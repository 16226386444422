import { Helmet } from 'react-helmet'
import { FC } from 'react'

const Meta: FC<{ title: string }> = ({ title }) => {
  return (
    <Helmet>
      <title>HomeLab: {title}</title>
    </Helmet>
  )
}

export default Meta
