import { Alert, Button, Form, Modal } from 'react-bootstrap'
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { usePingMutation } from '../generated/urql.lab-user'
import { Check, Trash } from 'react-bootstrap-icons'
import { AuthContext } from '../provider/auth'
import { guessStorageAddress } from '../lib/api'
import { ValidTestResult } from './TestResultUploadModal'
import { isIdentity } from '../lib/identity'

export type TestResultConfirmationModalType = {
  show: (testResults: ValidTestResult[]) => void
}

function renderIdentity(identity: unknown) {
  if (!isIdentity(identity)) {
    return <>?</>
  }

  switch (identity.type) {
    case 'id_card':
      return (
        <>
          <span>
            Dowód osobisty ({identity.countryCode}, {identity.dateOfBirth}):{' '}
          </span>
          <strong className="fs-3">{identity.value}</strong>
        </>
      )
    case 'passport':
      return (
        <>
          <span>
            Paszport ({identity.countryCode}, {identity.dateOfBirth}):{' '}
          </span>
          <strong className="fs-3">{identity.value}</strong>
        </>
      )
    case 'pesel':
      return (
        <>
          <span>PESEL: </span>
          <strong className="fs-3">{identity.value}</strong>
        </>
      )
  }
}

const TestResultConfirmationModal = forwardRef<
  TestResultConfirmationModalType,
  { onFinished: (testResults: ValidTestResult[]) => void }
>(({ onFinished }, ref) => {
  const [show, setShow] = useState(false)
  const [generalError, setGeneralError] = useState<string>()
  const [testResults, setTestResults] = useState<ValidTestResult[]>([])
  const [confirmedTestResults, setConfirmedTestResults] = useState<
    ValidTestResult[]
  >([])
  const [currentIdx, setCurrentIdx] = useState(0)
  const auth = useContext(AuthContext)
  const [, ping] = usePingMutation()
  const [dataURI, setDataURI] = useState<string>()
  const [uploading, setUploading] = useState(false)
  const [criticalParameter, setCriticalParameter] = useState(false)

  useImperativeHandle(ref, () => ({
    show: (testResults) => {
      setShow(true)
      setTestResults(testResults)
      setCurrentIdx(0)
      setConfirmedTestResults([])
      setCriticalParameter(false)
    },
  }))

  const uploadOrderTestResult = useCallback(
    async (testResult: ValidTestResult) => {
      setUploading(true)
      try {
        await ping({}, { requestPolicy: 'network-only' })

        const formData = new FormData()
        formData.append('file', testResult.file)
        formData.append(
          'orderTestIds',
          testResult.orderTests
            .map((orderTest) => String(orderTest.id))
            .join(',')
        )
        if (criticalParameter) {
          formData.append('criticalParameter', 'true')
        }

        const response = await fetch(
          `${guessStorageAddress()}/ordertestresults`,
          {
            method: 'POST',
            body: formData,
            headers: {
              authorization: `Bearer ${auth.accessToken}`,
            },
          }
        )

        if (!response.ok) {
          const error = await response.json()
          setGeneralError(error.message)
        }
      } finally {
        setUploading(false)
      }
    },
    [auth.accessToken, criticalParameter]
  )

  const next = useCallback(() => {
    if (currentIdx + 1 >= testResults.length) {
      setShow(false)
      onFinished(confirmedTestResults)
    } else {
      setCurrentIdx(currentIdx + 1)
    }
  }, [currentIdx, onFinished, testResults.length])

  const testResult = testResults[currentIdx]

  useEffect(() => {
    if (testResult) {
      const reader = new FileReader()
      reader.readAsDataURL(testResult.file)
      reader.addEventListener(
        'load',
        function () {
          if (reader.result && typeof reader.result === 'string') {
            setDataURI(reader.result)
          }
        },
        false
      )
    }
  }, [testResult])

  if (!testResult) {
    return null
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false)
      }}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Weryfikacja wyniku</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {generalError && <Alert variant="danger">{generalError}</Alert>}
        <div className="d-flex justify-content-between gap-3">
          <div>
            <span>Pacjent: </span>
            <strong className="fs-3">
              {testResult.orderTests[0].firstName}{' '}
              {testResult.orderTests[0].lastName}
            </strong>
          </div>
          <div>{renderIdentity(testResult.orderTests[0].identity)}</div>
        </div>
        <hr className="mt-2" />
        {dataURI && (
          <object
            data={dataURI}
            type="application/pdf"
            width="100%"
            height="500"
          >
            <p>Nie można wyświetlić pliku PDF</p>
          </object>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Form.Check
          type="switch"
          label={<span className="fw-bold">Parametr krytyczny</span>}
          checked={criticalParameter}
          onChange={() => setCriticalParameter(!criticalParameter)}
          className="me-auto"
        />
        <Button
          variant="danger"
          disabled={uploading}
          onClick={next}
          className="d-flex align-items-center"
        >
          <Trash size={20} className="me-1" />
          Usuń
        </Button>
        <Button
          variant="success"
          className="d-flex align-items-center"
          disabled={uploading}
          onClick={async () => {
            await uploadOrderTestResult(testResult)
            setConfirmedTestResults([...confirmedTestResults, testResult])
            next()
          }}
        >
          <Check size={20} className="me-1" />
          Zaakceptuj
        </Button>
      </Modal.Footer>
    </Modal>
  )
})

export default TestResultConfirmationModal
