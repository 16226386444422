export type ClientIdentity = {
  value: string
} & (
  | {
      type: 'pesel'
    }
  | { type: 'id_card' | 'passport'; countryCode: string; dateOfBirth: string }
)

export function isIdentity(identity: any): identity is ClientIdentity {
  return (
    typeof identity === 'object' &&
    identity !== null &&
    'type' in identity &&
    ['pesel', 'id_card', 'passport'].includes(identity.type)
  )
}
