import { authExchange } from '@urql/exchange-auth'
import {
  cacheExchange,
  createClient,
  dedupExchange,
  fetchExchange,
  makeOperation,
} from 'urql'
import {
  RefreshTokenDocument,
  RefreshTokenMutation,
  RefreshTokenMutationVariables,
} from '../generated/urql.anonymous'
import { Auth, isTokenExpired } from '../provider/auth'

function guessApiAddress() {
  const host = window.location.host
  if (host.toLowerCase().indexOf('localhost') > -1) {
    return 'http://localhost:4020/v1/graphql'
  } else {
    return 'https://' + host.replace(/^[a-zA-Z0-9]+\./, 'api.') + '/v1/graphql'
  }
}

export const getClient = (auth: Auth) => {
  return createClient({
    url: guessApiAddress(),
    fetchOptions: () => ({
      mode: 'cors',
      credentials: 'include',
      referrerPolicy: 'no-referrer-when-downgrade',
    }),
    exchanges: [
      dedupExchange,
      cacheExchange,
      // @ts-ignore
      authExchange<string>({
        // @ts-ignore
        addAuthToOperation: ({ authState, operation }) => {
          // the token isn't in the auth state, return the operation without changes
          if (!authState) {
            return operation
          }

          const queryName = (operation as any).query.definitions[0].name.value

          if (
            [
              'Login',
              'RefreshToken',
              'PasswordResetInit',
              'PasswordResetFinish',
            ].includes(queryName)
          ) {
            return operation
          }

          // fetchOptions can be a function (See Client API) but you can simplify this based on usage
          const fetchOptions =
            typeof operation.context.fetchOptions === 'function'
              ? operation.context.fetchOptions()
              : operation.context.fetchOptions || {}

          // @ts-ignore
          return makeOperation(operation.kind, operation, {
            ...operation.context,
            fetchOptions: {
              ...fetchOptions,
              headers: {
                ...fetchOptions.headers,
                Authorization: `Bearer ${authState}`,
              },
              credentials: 'include',
            },
          })
        },
        willAuthError: () => {
          if (!auth.accessToken) {
            return true
          }

          return isTokenExpired(auth.accessToken, 15000)
        },
        didAuthError: ({ error }) => {
          return error.graphQLErrors.some(
            (e) => e.extensions?.code === 'invalid-jwt'
          )
        },
        getAuth: async ({ mutate }) => {
          const result = await mutate<
            RefreshTokenMutation,
            RefreshTokenMutationVariables
          >(RefreshTokenDocument)

          if (result.data?.lab_user_refresh_token) {
            await auth.setAccessToken(
              result.data.lab_user_refresh_token.accessToken
            )

            return result.data.lab_user_refresh_token.accessToken
          }

          return null
        },
      }),
      fetchExchange,
    ],
  })
}
