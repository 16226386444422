import { useLogoutMutation } from '../generated/urql.lab-user'
import { useContext } from 'react'
import { AuthContext } from '../provider/auth'
import { useNavigate } from 'react-router'

export function useLogout() {
  const [, logout] = useLogoutMutation()
  const auth = useContext(AuthContext)
  const navigate = useNavigate()

  return async () => {
    if (auth.isLogged) {
      console.log('logging out..')
      await logout(undefined, { requestPolicy: 'network-only' })
      await auth.clearTokens()
      navigate('/')
      window.location.reload()
    }
  }
}
