import Meta from '../components/Meta'
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  GetReportedOrdersDocument,
  GetReportedOrdersQuery,
  GetReportedOrdersQueryVariables,
  Order_State_Enum_Enum,
} from '../generated/urql.lab-user'
import { useClient } from 'urql'
import { Badge, Button, ButtonGroup } from 'react-bootstrap'
import { Search } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router'
import { AuthContext } from '../provider/auth'
import moment from 'moment'
import { InfiniteTable } from '../components/InfiniteTable'
import { OrderState2BgVariant, OrderState2Label } from '../lib/order'

const BatchSize = 20

const Reported: FC = () => {
  // page content
  const pageTitle = 'Zgłoszone'
  const [orders, setOrders] = useState<GetReportedOrdersQuery['order']>([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const data = useMemo(() => orders, [orders])
  const client = useClient()
  const navigate = useNavigate()
  const auth = useContext(AuthContext)

  const loadMoreRows = useCallback(
    async ({ refetch = false }: { refetch: boolean } = { refetch: false }) => {
      const { data, error } = await client
        .query<GetReportedOrdersQuery, GetReportedOrdersQueryVariables>(
          GetReportedOrdersDocument,
          {
            laboratoryId: auth.laboratoryId,
            offset: refetch ? 0 : offset,
            limit: BatchSize,
          },
          { requestPolicy: refetch ? 'network-only' : undefined }
        )
        .toPromise()

      if (error) {
        console.error(error)
        return
      }

      if (data) {
        setOrders(refetch ? data.order : orders.concat(data.order))
        setOffset(refetch ? BatchSize : offset + BatchSize)
        setCount(data.order_aggregate.aggregate?.count || 0)
      }
    },
    [client, orders, offset]
  )

  useEffect(() => {
    loadMoreRows({ refetch: true }).then(() => {})
  }, [])

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-4" />
      <InfiniteTable<GetReportedOrdersQuery['order'][number]>
        data={data}
        columns={[
          {
            Header: 'Data',
            id: 'date',
            accessor: (rowData) => (
              <span>
                {moment(rowData.orderCollections[0].collectionDate).format(
                  'D MMM YYYY HH:mm'
                )}
              </span>
            ),
          },
          {
            Header: 'Badania',
            id: 'tests',
            accessor: (rowData) => (
              <span>
                {rowData.orderTests.map(({ test }) => test.name).join(', ')}
              </span>
            ),
          },
          {
            Header: 'Klient',
            id: 'client',
            accessor: (rowData) => (
              <div>
                {rowData.profiles.map((profile) => (
                  <div className="fw-bold">
                    {profile.firstName} {profile.lastName}
                  </div>
                ))}
                <div>
                  {rowData.orderCollections[0].city}{' '}
                  {rowData.orderCollections[0].postalCode}
                </div>
              </div>
            ),
          },
          {
            Header: 'Status',
            id: 'status',
            accessor: (rowData) => (
              <Badge
                bg={
                  OrderState2BgVariant[
                    rowData.currentState as Order_State_Enum_Enum
                  ]
                }
              >
                {
                  OrderState2Label[
                    rowData.currentState as Order_State_Enum_Enum
                  ]
                }
              </Badge>
            ),
          },
          {
            Header: '',
            id: 'actions',
            accessor: (rowData) => (
              <div className="d-flex justify-content-end">
                <ButtonGroup>
                  <Button
                    variant="primary"
                    onClick={() => navigate(`/order/${rowData.id}`)}
                  >
                    <Search size={18} />
                  </Button>
                </ButtonGroup>
              </div>
            ),
          },
        ]}
        loadMoreRows={loadMoreRows}
        hasMore={offset < count}
      />
    </div>
  )
}

export default Reported
