import { Alert, Button, FloatingLabel, Form, Modal } from 'react-bootstrap'
import { forwardRef, useImperativeHandle, useState } from 'react'
import { useUpdateLaboratoryCodeMutation } from '../generated/urql.lab-user'

export type LaboratoryCodeModalType = {
  show: (orderTestId: number, value?: string) => void
}

const LaboratoryCodeModal = forwardRef<
  LaboratoryCodeModalType,
  {
    onChange: () => void
  }
>(({ onChange }, ref) => {
  const [show, setShow] = useState(false)
  const [orderTestId, setOrderTestId] = useState<number>()
  const [laboratoryCode, setLaboratoryCode] = useState<string>()
  const [, updateLaboratoryCode] = useUpdateLaboratoryCodeMutation()
  const [generalError, setGeneralError] = useState<string>()

  useImperativeHandle(ref, () => ({
    show: (id, value) => {
      setOrderTestId(id)
      setLaboratoryCode(value)
      setShow(true)
    },
  }))

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false)
        setLaboratoryCode(undefined)
        setOrderTestId(undefined)
      }}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Kod wewnętrzny laboratorium</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {generalError && <Alert variant="danger">{generalError}</Alert>}
        <FloatingLabel
          controlId="floatingInput"
          label="Kod wewnętrzny"
          className="mb-3"
        >
          <Form.Control
            type="email"
            placeholder="Email"
            value={laboratoryCode}
            onChange={(event) => setLaboratoryCode(event.target.value)}
          />
        </FloatingLabel>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShow(false)
          }}
        >
          Anuluj
        </Button>
        <Button
          variant="success"
          onClick={async () => {
            if (!orderTestId) {
              setGeneralError('Missing orderTestId')
              return
            }

            const { error } = await updateLaboratoryCode({
              orderTestId,
              laboratoryCode,
            })

            if (error) {
              setGeneralError(error.message)
              return
            }

            setShow(false)
            onChange()
          }}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  )
})

export default LaboratoryCodeModal
