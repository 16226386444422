import React, {
  CSSProperties,
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import Webcam from 'react-webcam'
import Quagga from '@ericblade/quagga2'

const ScreenshotSize = {
  width: 800,
  height: 600,
}

const ProbeInterval = 200

export type BarcodeScannerType = {
  reset: () => void
}

const BarcodeScanner = forwardRef<
  BarcodeScannerType,
  {
    width: number
    height: number
    onBarcode: (barcode: string) => void
    style?: CSSProperties & { position?: 'relative' | 'absolute' | 'fixed' }
  }
>(({ width, height, onBarcode, style }, ref): React.ReactElement => {
  const webcamRef = useRef<Webcam>(null)
  const canvasRef = useRef<HTMLDivElement>(null)
  const [resultPoints, setResultPoints] = useState<{ x: number; y: number }[]>()
  const [code, setCode] = useState<string>()
  useImperativeHandle(ref, () => ({
    reset: () => {
      setCode(undefined)
      setResultPoints(undefined)
    },
  }))

  useEffect(() => {
    const interval = setInterval(async () => {
      if (webcamRef?.current) {
        const screenshot = await webcamRef.current.getScreenshot(ScreenshotSize)
        if (screenshot) {
          const result = await Quagga.decodeSingle({
            locate: true,
            decoder: {
              readers: ['code_128_reader'],
            },
            locator: {
              halfSample: true,
            },
            src: screenshot,
          })

          if (result?.codeResult?.code) {
            new Audio('/barcode-scanner-beep.mp3').play().then()

            setResultPoints(result.line)

            if (canvasRef.current) {
              canvasRef.current.style.backgroundImage = `url('${screenshot}')`
            }

            setCode(result.codeResult.code)
            onBarcode(result.codeResult.code)
          }
        }
      }
    }, ProbeInterval)
    return () => clearInterval(interval)
  }, [height, onBarcode, webcamRef, width])

  return (
    <div style={{ ...style, position: 'relative', width, height }}>
      {!resultPoints && (
        <Webcam
          ref={webcamRef}
          style={{
            width: '100%',
            height: '100%',
          }}
          screenshotFormat="image/png"
          videoConstraints={ScreenshotSize}
        />
      )}
      <div
        ref={canvasRef}
        style={{
          display: resultPoints ? 'block' : 'none',
          width: '100%',
          height: '100%',
          position: 'relative',
          background: 'no-repeat',
          backgroundSize: 'contain',
        }}
      />
      {resultPoints && (
        <div
          style={{
            border: '3px solid #f00b',
            borderRadius: '3px',
            position: 'absolute',
            top: resultPoints[0].y,
            left: resultPoints[0].x - 10,
            width: resultPoints[1].x - resultPoints[0].x + 20,
            height: resultPoints[1].y - resultPoints[0].y,
          }}
        >
          <div
            style={{
              position: 'absolute',
              left: -20,
              right: -20,
              top: -40,
              alignItems: 'center',
            }}
          >
            <div
              style={{
                backgroundColor: '#000a',
                borderRadius: 10,
                padding: '1px 8px',
                display: 'flex',
              }}
            >
              <span
                style={{
                  flex: 1,
                  color: '#ffb849',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: 18,
                }}
              >
                {code}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
})

export default BarcodeScanner
