import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss'
import { useMemo } from 'react'
import { Container } from 'react-bootstrap'
import { CookiesProvider } from 'react-cookie'
import { Route, Routes } from 'react-router-dom'
import { Provider as UrqlProvider } from 'urql'
import Layout from './layout/Layout'
import { getClient } from './lib/client'
import NotFound from './pages/NotFound'
import { AuthContext, useAuth } from './provider/auth'
import Login from './pages/Login'
import PasswordResetInit from './pages/password-reset/PasswordResetInit'
import PasswordResetFinish from './pages/password-reset/PasswordResetFinish'
import Reported from './pages/Reported'
import Accepted from './pages/Accepted'
import Finished from './pages/Finished'
import Order from './pages/Order'

const App = () => {
  const auth = useAuth()
  const client = useMemo(() => getClient(auth), [auth])

  return (
    <CookiesProvider>
      <AuthContext.Provider value={auth}>
        <UrqlProvider value={client}>
          <Layout>
            <Container>
              <Routes>
                {!auth.accessToken && (
                  <>
                    <Route path="/" element={<Login />} />
                    <Route
                      path="/passwordreset/init"
                      element={<PasswordResetInit />}
                    />
                    <Route
                      path="/passwordreset/finish"
                      element={<PasswordResetFinish />}
                    />
                  </>
                )}
                {!!auth.accessToken && (
                  <>
                    <Route path="/" element={<Reported />} />
                    <Route path="/accepted" element={<Accepted />} />
                    <Route path="/finished" element={<Finished />} />
                    <Route path="/order/:id" element={<Order />} />
                    <Route element={<NotFound />} />
                  </>
                )}
              </Routes>
            </Container>
          </Layout>
        </UrqlProvider>
      </AuthContext.Provider>
    </CookiesProvider>
  )
}

export default App
