import { useGetLabUserQuery } from '../generated/urql.lab-user'
import { useMemo } from 'react'

export function useLoggedUser() {
  const [{ data: labUserData }] = useGetLabUserQuery({
    requestPolicy: 'network-only',
  })

  return {
    initials: useMemo(() => {
      if (labUserData?.lab_user[0]) {
        const name = labUserData.lab_user[0].name
        return name
          .split(' ')
          .map((part) => part[0])
          .join('')
      } else {
        return '?'
      }
    }, [labUserData]),
    name: labUserData?.lab_user[0].name || '?',
  }
}
